import React from 'react';
import axios from "axios";
import { useEffect, useState, useCallback } from "react";

export default function Services({localapibase, locallabels}) {
	const [webtext1, setwebtext1] = useState([]);
	const [webtext2, setwebtext2] = useState([]);
	const [webtext3, setwebtext3] = useState([]);
	
	const gettext1 = useCallback(() => {
        axios.get(localapibase+'getwebsitetext.php/srvselling').then(function(response) {
            setwebtext1(response.data);
        }).catch(error => {
			 console.log(error.response.data.error);
		});
    }, [localapibase]);
	
	const gettext2 = useCallback(() => {
        axios.get(localapibase+'getwebsitetext.php/srvrenting').then(function(response) {
            setwebtext2(response.data);
        }).catch(error => {
			 console.log(error.response.data.error);
		});
    }, [localapibase]);
	
	const gettext3 = useCallback(() => {
        axios.get(localapibase+'getwebsitetext.php/srvbuying').then(function(response) {
            setwebtext3(response.data);
        }).catch(error => {
			 console.log(error.response.data.error);
		});
    }, [localapibase]);
	
	useEffect(() => {
        gettext1();
		gettext2();
		gettext3();
    }, [gettext1, gettext2, gettext3]);
	
	return(
		<div id="dservices" className="m-0 border-0 p-0">	
			<h3 className="m-0 border-0 ps-4 pt-3">{locallabels.services}</h3> 
			<div className="container-fluid justify-content-start m-0 border-0 p-3 row row-cols-1">

				<div className="card mb-3 tcard">
					<div className="row row-cols-md-2">
						<div className="col-md-4">
							<img src="./Kepek/services/propsell.jpg" className="card-img-top tcardimg float-md-start me-3" alt={locallabels.propertysale} />
						</div>
						<div className="col-md-8">
							<div className="card-body p-0 pt-3">
								<h5><b>{(webtext1[0] || []).title}</b></h5>
								<p className="card-text">{(webtext1[0] || []).prologue}</p>
								<div dangerouslySetInnerHTML={{ __html: (webtext1[0] || []).description }} />
							</div>
						</div>
						
					</div>
					<div className="card-img-overlay text-start w-25 h-50">
						<button className="btn redbg text-white opacity-75">{locallabels.propertysale}</button>
					</div>
				</div>
				
				<div className="card mb-3 tcard">
					<div className="row row-cols-md-2">
						<div className="col-md-4">
							<img src="./Kepek/services/proprent.jpg" className="card-img-top tcardimg float-md-start me-3" alt={locallabels.propertyrent} />
						</div>
						<div className="col-md-8">
							<div className="card-body p-0 pt-3">
								<h5><b>{(webtext2[0] || []).title}</b></h5>
								<p className="card-text">{(webtext2[0] || []).prologue}</p>
								<div dangerouslySetInnerHTML={{ __html: (webtext2[0] || []).description }} />
							</div>
						</div>
					</div>
					<div className="card-img-overlay text-start w-25 h-50">
						<button className="btn redbg text-white opacity-75">{locallabels.propertyrent}</button>
					</div>
				</div>
				
				<div className="card mb-3 tcard">		
					<div className="row row-cols-md-2">
						<div className="col-md-4">
							<img src="./Kepek/services/propbuy.jpg" className="card-img-top tcardimg float-md-start me-3" alt={locallabels.propertybuy} />
						</div>
						<div className="col-md-8">
							<div className="card-body p-0 pt-3">								
								<h5><b>{(webtext3[0] || []).title}</b></h5>
								<p className="card-text">{(webtext3[0] || []).prologue}</p>
								<div dangerouslySetInnerHTML={{ __html: (webtext3[0] || []).description }} />
							</div>
						</div>
						
					</div>		
					<div className="card-img-overlay text-start w-25 h-50">
						<button className="btn redbg text-white opacity-75">{locallabels.propertybuy}</button>
					</div>
				</div>
				
			</div>	
			
		</div>	
	);
}  