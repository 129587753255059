import React from 'react';
import axios from "axios";
import { useEffect, useState, useCallback } from "react";

export default function Services({localapibase, locallabels}) {
	const [agency, setagency] = useState([]);
	
	const getagency = useCallback(() => {
        axios.get(localapibase+'getfooteragency.php').then(function(response) {
            setagency(response.data);
        }).catch(error => {
			 console.log(error.response.data.error);
		});
    }, [localapibase]);
	
	useEffect(() => {
        getagency();
    }, [getagency]);
	
	return(
		<div id="dservices" className="m-0 border-0 p-0">	
			<h3 className="m-0 border-0 ps-4 pt-3">{locallabels.contact}</h3> 
			<div className="container-fluid justify-content-start m-0 border-0 p-3 row row-cols-1">
				<div className="row row-cols-md-2">
					<div className="col">
						<div className="table-responsive">
							<table id="contactinfo" className="table table-borderless">
							<tbody>
								<tr>
									<th className="align-top">
						{locallabels.agencyaddress}:
									</th>
									<td>
					<p>{(agency[0] || []).agency_name}</p>
					<p>{(agency[0] || []).agency_address}</p>
									</td>
								</tr>
								<tr className="mt-3">
									<th className="align-top">
{locallabels.agencycontact}:
									</th>
									<td>
					<p><span className="fa fa-phone"></span> {(agency[0] || []).phone1}<br />
					{(agency[0] || []).phone2 !== "" ? 
					<span><span className="fa fa-phone"></span> {(agency[0] || []).phone2}<br /></span>
					: ""}
					<span className="fa fa-envelope"></span> {(agency[0] || []).email}</p>
									</td>
								</tr>
							</tbody>
							</table>
						</div>
					</div>
					<div className="col text-center">
						<div dangerouslySetInnerHTML={{ __html: (agency[0] || []).agency_map }} />
					</div>
				</div>
				
			</div>	
			
		</div>	
	);
}  