import React from 'react';
import axios from "axios";
import { useEffect, useState, useCallback } from "react";

export default function DataProtecting({localapibase, locallabels}) {
	const [webtext, setwebtext] = useState([]);
	
	const gettext = useCallback(() => {
        axios.get(localapibase+'getwebsitetext.php/dataprotecting').then(function(response) {
            setwebtext(response.data);
        }).catch(error => {
			 console.log(error.response.data.error)
		});
    }, [localapibase]);
	
	useEffect(() => {
        gettext();
    }, [gettext]);
	
	return(
		<div id="dmain" className="m-0 border-0 p-0">	
			<div className="container text-justify p-3 vh-100">
				<h3 className="text-center mt-3 mb-5">{(webtext[0] || []).title}</h3> 
				<p>{(webtext[0] || []).prologue}</p>
				<p>{(webtext[0] || []).description}</p>
			</div>			
		</div>	
	);
}  