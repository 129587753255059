import React from 'react';
import axios from "axios";
import { useEffect, useState, useCallback } from "react";

export default function MainFooter({localapibase, locallabels}) {
	const [agency, setagency] = useState([]);
	
	const getagency = useCallback(() => {
        axios.get(localapibase+'getfooteragency.php').then(function(response) {
            setagency(response.data);
        }).catch(error => {
			 console.log(error.response.data.error);
		});
    }, [localapibase]);
	
	useEffect(() => {
        getagency();
    }, [getagency]);
	
	return(
		<div className="m-0 border-0 p-0 home-footer">	
			<footer className="container-fluid justify-content-end p-3 mb-4 footer">				
				
				<div className="row">
				<div className="col-md-3">
					<p><b>{locallabels.agencyaddress}:</b></p>
					<p>{(agency[0] || []).agency_name}</p>
					<p>{(agency[0] || []).agency_address}</p>
				</div>
				
				<div className="col-md-4">
					<p><b>{locallabels.agencycontact}:</b></p>
					<p><span className="fa fa-phone"></span> {(agency[0] || []).phone1}<br />
					{(agency[0] || []).phone2 !== "" ? 
					<span><span className="fa fa-phone"></span> {(agency[0] || []).phone2}<br /></span>
					: ""}
					<span className="fa fa-envelope"></span> {(agency[0] || []).email}</p>
					<p><b>{locallabels.findus}:</b></p>
					<p><a href="/#" className="btn btn-icon btn-round btn-o">
					<span className="fa fa-facebook footergray fs-4"></span></a> 
					<a href="/#" className="btn btn-icon btn-round btn-o">
					<span className="fa fa-twitter footergray fs-4"></span></a> </p>
				</div>
				
				<div className="col-md-3">
					<p><b>{locallabels.sitemap}:</b></p>
					<p><a href="./services"className="">{locallabels.propertysale}</a> <br />
					<a href="./services"className="">{locallabels.propertyrent}</a> <br />
					<a href="./services"className="">{locallabels.propertybuy}</a> <br />
					{locallabels.cooperation}<br />
					{locallabels.financialsupport}<br />
					{locallabels.whoweare}?<br />
					{locallabels.workwithus}</p>
				</div>
				
				<div className="col-md-2">
					<a href="./dataprotecting"className="">{locallabels.dataprotecting}</a> 
				</div>
				</div>
				<div className="row">
				<div className="col-md-3">
				<a href="./contact"className="">{locallabels.agencymap}</a> 
				</div>
				<div className="col-md-4">
				{locallabels.sendemail}
				</div>
				<div className="col">
				{locallabels.offers}
				</div>
				</div>
				
			</footer>		
		</div>	
	);
}  