import React from 'react';
import axios from "axios"
import { useEffect, useState, useCallback } from "react";

export default function MainFilter({localapibase, locallabels, setlocalfilter, showfield}) {
	const [categories, setcategories] = useState([]);
	const [propertytypes, setpropertytypes] = useState([]);
	const [propertysubtypes, setpropertysubtypes] = useState([]);
	const [propertystates, setpropertystates] = useState([]);
	const [orientations, setorientations] = useState([]);
	const [heatingtypes, setheatingtypes] = useState([]);
	const [parkings, setparkings] = useState([]);
	const [comforts, setcomforts] = useState([]);
	const [baths, setbaths] = useState([]);
	const [levels, setlevels] = useState([]);
	const [ceilingheights, setceilingheights] = useState([]);
	const [furnisheds, setfurnisheds] = useState([]);
	const [pets, setpets] = useState([]);
	const [tenants, settenants] = useState([]);
	const [districts, setdistricts] = useState([]);
	const [counties, setcounties] = useState([]);
	const [cities, setcities] = useState([]);
	
	const [currentcategory, setcurrentcategory] = useState(0);
	const [currentpropertytype, setcurrentpropertytype] = useState(0);
	const [selecteddistricts, setselecteddistricts] = useState([]);
	const [selectedcounties, setselectedcounties] = useState([]);
	const [selectedcities, setselectedcities] = useState([]);
	const [currentpropertyid, setcurrentpropertyid] = useState("");
	const [currentpricefrom, setcurrentpricefrom] = useState("");
	const [currentpriceto, setcurrentpriceto] = useState("");
	const [currentnetsizefrom, setcurrentnetsizefrom] = useState("");
	const [currentnetsizeto, setcurrentnetsizeto] = useState("");
	const [currentnetroomfrom, setcurrentnetroomfrom] = useState("");
	const [currentnetroomto, setcurrentnetroomto] = useState("");
	const [currentgreenhome, setcurrentgreenhome] = useState("n");
	const [currentnewbuilt, setcurrentnewbuilt] = useState("n");
	const [selectedsubtypes, setselectedsubtypes] = useState([]);
	const [selectedstate, setselectedstate] = useState([]);
	const [selectedorientation, setselectedorientation] = useState([]);
	const [selectedheatingtypes, setselectedheatingtypes] = useState([]);
	const [selectedparking, setselectedparking] = useState([]);
	const [selectedcomfort, setselectedcomfort] = useState([]);
	const [selectedbath, setselectedbath] = useState([]);
	const [currentlevelfrom, setcurrentlevelfrom] = useState(0);
	const [currentlevelto, setcurrentlevelto] = useState(0);
	const [currentceilingheight, setcurrentceilingheight] = useState(0);
	const [currentfurnished, setcurrentfurnished] = useState(0);
	const [currentsmoking, setcurrentsmoking] = useState("n");
	const [currentelevator, setcurrentelevator] = useState("n");
	const [currentmechanised, setcurrentmechanised] = useState("n");
	const [currentisaccessable, setcurrentisaccessable] = useState("n");
	const [currentcellar, setcurrentcellar] = useState("n");
	const [currentpet, setcurrentpet] = useState(0);
	const [currentgardenconnection, setcurrentgardenconnection] = useState("n");
	const [currentbalcon, setcurrentbalcon] = useState("n");
	const [currenttenant, setcurrenttenant] = useState(0);
	const [currentaircondition, setcurrentaircondition] = useState("n");
	const [currenttext, setcurrenttext] = useState("");
	const [listsloaded, setlistsloaded] = useState(false);
	
	var filterStyle = {
		backgroundImage: "url(./Kepek/filterbg.jpg)",
		backgroundAttachment: "fixed",
		backgroundPosition: "top",
		backgroundRepeat: "no-repeat",	
		backgroundSize: "cover"
	};
	
	const getlist = (inputtable, inputfilter) => {
		var inputparm = "";
		
		inputparm = "{";
		inputparm += '"tablename": "' + inputtable +'"';
		if (inputfilter !== "")
			inputparm += ', "filters": ' + inputfilter;
		inputparm += "}";
        axios.post(localapibase+'getlist.php',inputparm).then(function(response) {		
			if (inputtable === "propertysubtypes")
			{
				setpropertysubtypes(response.data);
				setselectedsubtypes([]);			
			}						
        });
    };	

	const getlists = useCallback(() => {
		Promise.all([
			axios.post(localapibase+'getlist.php','{"tablename": "categories"}'),
			axios.post(localapibase+'getlist.php','{"tablename": "propertytypes"}'),
			axios.post(localapibase+'getlist.php','{"tablename": "counties"}'),
			axios.post(localapibase+'getdistrict.php'),			
			axios.post(localapibase+'getlist.php','{"tablename": "propertystates"}'),
			axios.post(localapibase+'getlist.php','{"tablename": "orientation"}'),
			axios.post(localapibase+'getlist.php','{"tablename": "heatingtypes"}'),
			axios.post(localapibase+'getlist.php','{"tablename": "parking"}'),
			axios.post(localapibase+'getlist.php','{"tablename": "comfort"}'),
			axios.post(localapibase+'getlist.php','{"tablename": "bath"}'),
			axios.post(localapibase+'getlist.php','{"tablename": "propertylevels"}'),
			axios.post(localapibase+'getlist.php','{"tablename": "ceilingheight"}'),
			axios.post(localapibase+'getlist.php','{"tablename": "furnished"}'),
			axios.post(localapibase+'getlist.php','{"tablename": "pet"}'),
			axios.post(localapibase+'getlist.php','{"tablename": "tenant"}')
			
		]).then(async([rescategories, respropertytypes, rescounties, resdistricts, respropertystate, resorientation, resheatingtypes, resparking, rescomfort, resbath, respropertylevels, resceilingheight, resfurnished, respet, restenant
		]) => {
			const tmpcategories = await rescategories.data;
			setcategories(tmpcategories);
			if (tmpcategories.length > 0)
				setcurrentcategory(tmpcategories[0]["category_id"]);
			
			const tmppropertytypes = await respropertytypes.data;
			setpropertytypes(tmppropertytypes);	
			if (tmppropertytypes.length > 0)
				setcurrentpropertytype(tmppropertytypes[0]["property_type_id"]);

			const tmpcounties = await rescounties.data;
			setcounties(tmpcounties);	

			const tmpdistricts = await resdistricts.data;
			setdistricts(tmpdistricts);	
			
			const tmppropertystate = await respropertystate.data;
			setpropertystates(tmppropertystate);			
			
			const tmporientation = await resorientation.data;
			setorientations(tmporientation);	
			
			const tmpheatingtypes = await resheatingtypes.data;
			setheatingtypes(tmpheatingtypes);
			
			const tmpparkings = await resparking.data;
			setparkings(tmpparkings);
			
			const tmpcomfort = await rescomfort.data;
			setcomforts(tmpcomfort);
			
			const tmpbaths = await resbath.data;
			setbaths(tmpbaths);
			
			const tmplevels = await respropertylevels.data;
			setlevels(tmplevels);
			if (tmplevels.length > 0)
			{
				setcurrentlevelfrom(0); //tmplevels[0]["property_level_id"]);
				setcurrentlevelto(0); //tmplevels[0]["property_level_id"]);
			}
			
			const tmpceilingheight = await resceilingheight.data;
			setceilingheights(tmpceilingheight);	
			if (tmpceilingheight.length > 0)
				setcurrentceilingheight(0); //tmpceilingheight[0]["ceiling_height_id"]);
			
			const tmpfurnished = await resfurnished.data;
			setfurnisheds(tmpfurnished);	
			if (tmpfurnished.length > 0)
				setcurrentfurnished(0); //tmpfurnished[0]["furnished_id"]);
			
			const tmppets = await respet.data;
			setpets(tmppets);
			if (tmppets.length > 0)
				setcurrentpet(0); //tmppets[0]["pet_id"]);
			
			const tmptenants = await restenant.data;
			settenants(tmptenants);
			if (tmptenants.length > 0)
				setcurrenttenant(0); //tmptenants[0]["tenant_id"]);
			
			setlistsloaded(true);
		})
		.catch(error => {
			console.log(error);
		});
	}, [localapibase]);
/*
	const getfilters = useCallback(() => {
        axios.get(localapibase+'ingatlanfilters.php').then(function(response) {
			setpropertytypes(response.data.propertytypes);			
            setcategories(response.data.categories);
			setfilters({"categories": response.data.categories[0].category_id});
			setpropertysubtypes(response.data.propertysubtypes);
			setpropertystates(response.data.propertystates);
			setorientations(response.data.orientation);
			setheatingtypes(response.data.heatingtypes);
			setparkings(response.data.parking);
			setcomforts(response.data.comfort);
			setbaths(response.data.bath);
			setlevels(response.data.propertylevels);
			setceilingheights(response.data.ceilingheight);
			setfurnisheds(response.data.furnished);
			setpets(response.data.pet);
			settenants(response.data.tenant);
			setdistricts(response.data.districts);	
			setcounties(response.data.counties);
			setcities(response.data.cities);				
        }).catch(error => {
			console.log(error);
		});
    }, [localapibase]);
*/	
	useEffect(() => {
		getlists();
        //getfilters();			
    }, [getlists, listsloaded/*getfilters*/]);
	
	if (!listsloaded)
		return <h5>Loading...</h5>
	
	const handlechange = (e) => {
		const name = e.target.name;
        const value = e.target.value;
		const tmpelement =e.target;

		if (name === "categories")
		{
			setcurrentcategory(value);
		}
		if (name === "propertytypes")
		{
			setcurrentpropertytype(value);
			getlist('propertysubtypes','[{"property_type_id":"'+value+'"}]');
		}
		if (name === "properyidtext")
		{
			setcurrentpropertyid(value);
		}
		if (name === "artol")
		{
			setcurrentpricefrom(value);
		}
		if (name === "arig")
		{
			setcurrentpriceto(value);
		}
		if (name === "merettol")
		{
			setcurrentnetsizefrom(value);
		}
		if (name === "meretig")
		{
			setcurrentnetsizeto(value);
		}
		if (name === "szobaszamtol")
		{
			setcurrentnetroomfrom(value);
		}
		if (name === "szobaszamig")
		{
			setcurrentnetroomto(value);
		}
		if (name === "greenhome")
		{
			setcurrentgreenhome(tmpelement.checked ? "y" : "n");
		}
		if (name === "newbuild")
		{
			setcurrentnewbuilt(tmpelement.checked ? "y" : "n");
		}
		if (name === "levelsfrom")
		{
			setcurrentlevelfrom(value);
		}
		if (name === "levelsto")
		{
			setcurrentlevelto(value);
		}
		if (name === "ceilingheight")
		{
			setcurrentceilingheight(value);
		}
		if (name === "furnished")
		{
			setcurrentfurnished(value);
		}
		if (name === "pet")
		{
			setcurrentpet(value);
		}
		if (name === "tenant")
		{
			setcurrenttenant(value);
		}
		if (name === "texttoinclude")
		{
			setcurrenttext(value);
		}
		if (name === "lift")
		{
			setcurrentelevator(tmpelement.checked ? "y" : "n");
		}
		if (name === "cellar")
		{
			setcurrentcellar(tmpelement.checked ? "y" : "n");
		}
		if (name === "balcon")
		{
			setcurrentbalcon(tmpelement.checked ? "y" : "n");
		}
		if (name === "machines")
		{
			setcurrentmechanised(tmpelement.checked ? "y" : "n");
		}
		if (name === "smoking")
		{
			setcurrentsmoking(tmpelement.checked ? "y" : "n");
		}
		if (name === "barrierfree")
		{
			setcurrentisaccessable(tmpelement.checked ? "y" : "n");
		}
		if (name === "withgarden")
		{
			setcurrentgardenconnection(tmpelement.checked ? "y" : "n");
		}
		if (name === "aircondition")
		{
			setcurrentaircondition(tmpelement.checked ? "y" : "n");
		}
	};
				
	const listselectedlocations = () => {
		return <div>
			<div id="districts">
				{ 
					selecteddistricts.map((district, key) => 
						<span key={key} className="me-2">
								<span className="badge rounded-pill lightbg text-black">{district.district_name}</span>
								<sup className="badge rounded-pill bg-danger handicon mb-3 align-self-top removex" onClick={() => removedistrict(district.district_id)}>
									X
								</sup>
						</span>
					)
				}
			</div>
			<div id="counties">
				{ 
					selectedcounties.map((county, key) => 
						<span key={key} className="me-2">
								<span className="badge rounded-pill lightbg text-black">{county.county_name}</span>
								<sup className="badge rounded-pill redbg handicon mb-3 align-self-top removex" onClick={() => removecounty(county.county_id)}>
									X
								</sup>
						</span>
					)
				}
			</div>
			<div id="cities">
				{ 
					selectedcities.map((city, key) => 
						<span key={key} className="me-2">
								<span className="badge rounded-pill lightbg text-black">{city.city_name}</span>
								<sup className="badge rounded-pill redbg handicon mb-3 align-self-top removex" onClick={() => removecity(city.city_id)}>
									X
								</sup>
						</span>
					)
				}
			</div>
		</div>
	}; 
	
	function filtermainrow() {
		return <div className="row row-cols-1 h-100">
			<div className="col align-self-top">
				<figure className="text-end">
					<blockquote className="blockquote text-center text-white slogen">
						<p className="fs-1">{locallabels.quotetext}</p>
					</blockquote>
					<figcaption className="blockquote-footer text-white slogen me-3">
						<cite title="Source Title text-white slogen">{locallabels.quoteauthor}</cite>
					</figcaption>
				</figure>
				<h3 className="text-center pt-2 text-white slogen">{locallabels.slogen}</h3>
				<h4 className="text-center px-3 pt-2 text-white slogen">{locallabels.slogen2}</h4>
			</div>
			
			<div className="col align-self-end">
			<div className="row row-cols-1 row-cols-md-2 align-items-end justify-content-start mx-3">
				<div className="col-md-10">
					<div className="row row-cols-1 row-cols-md-3">
						<div className="col-md-4 align-items-end mb-1">
							<select name="categories" id="categories" className="form-select mt-md-4" onChange={handlechange} value={currentcategory}>
							{
								categories.map((category, key) => 
									<option key={key} value={category.category_id}>
											{category.category_name}
									</option>
								)
							}
							</select>
						</div>
						<div className="col-md-4 align-items-end mb-1">
							<select name="propertytypes" id="propertytypes" className="form-select mt-md-4" onChange={handlechange} value={currentpropertytype}>
							{
								propertytypes.map((propertytype, key) => 
									<option key={key} value={propertytype.property_type_id}>
											{propertytype.property_type_name}
									</option>
								)
							}
							</select>
						</div>
						<div className="col-md-4 align-items-end mb-1">	
							{listselectedlocations()}				
							<div className="input-group">
								<button className="form-control mt-md-4 text-start" 
									data-bs-toggle="modal" data-bs-target="#dselectlocation" onClick={() => locationclick()}>
									{locallabels.selectlocationtitle}
								</button>	
							</div>					
						</div>				
					</div>
				</div>
				<div className="col-md-2">
					<div className="row row-cols-1 align-items-start justify-content-start p-0 m-0">
						<div className="col text-end p-0 m-0">	
							<button type="button" className="btn-lg redbg text-white border-0" onClick={() => filterclick()}><i className="fa fa-search fs-1"></i></button>
							<br />
							<a className="" href="./allproperties">
								<button type="button" className="btn-sm redbg text-white border-0 ms-3 mt-1">{locallabels.allproperties}</button>
							</a>
						</div>
					</div>
				</div>

			</div>
				
			<div className="row row-cols-1 row-cols-md-2 align-items-end justify-content-start mx-3 mt-1">	
				<div className="col-md-10">
					<div className="row row-cols-1 row-cols-md-3">
					
				<div className="col mt-1">
					<div className="input-group">
						<input type="text" className="form-control opacity-75" id="artol" name="artol" autoComplete="off" placeholder={locallabels.price + " " + locallabels.from}  onChange={handlechange} value={currentpricefrom} />
						<span className="align-middle opacity-75 mx-2">-</span>
						<input type="text" className="form-control opacity-75" id="arig" name="arig" autoComplete="off" placeholder={locallabels.price + " " + locallabels.to}  onChange={handlechange} value={currentpriceto} />
					</div>
				</div>
				<div className="col mt-1">
					<div className="input-group">
						<input type="text" className="form-control opacity-75" id="merettol" name="merettol" autoComplete="off" placeholder={locallabels.size + " " + locallabels.from}  onChange={handlechange} value={currentnetsizefrom} />
						<span className="align-middle opacity-75 mx-2">-</span>
						<input type="text" className="form-control opacity-75" id="meretig" name="meretig" autoComplete="off" placeholder={locallabels.size + " " + locallabels.to} onChange={handlechange} value={currentnetsizeto} />
					</div>
				</div>
				<div className="col mt-1">
					<div className="input-group">
						<input type="text" className="form-control opacity-75" id="szobaszamtol" name="szobaszamtol" autoComplete="off" placeholder={locallabels.rooms + " " + locallabels.from} onChange={handlechange} value={currentnetroomfrom} />
						<span className="align-middle opacity-75 mx-2">-</span>
						<input type="text" className="form-control opacity-75" id="szobaszamig" name="szobaszamig" autoComplete="off" placeholder={locallabels.rooms + " " + locallabels.to} onChange={handlechange} value={currentnetroomto} />
					</div>
				</div>
				
					</div>
				</div>
				<div className="col-md-2 align-items-end text-end opacity-75 mt-1">
					<button type="button" className="btn redbg text-white btn-sm" data-bs-toggle="collapse" data-bs-target="#cpdetailedsearch" aria-expanded="false" aria-controls="cpdetailedsearch">
						{locallabels.detailedsearch} &#8964;
					</button>
				</div>
			
			</div>
			<div className="row row-cols-2 align-items-end justify-content-start mx-3 mb-5">	
				<div className="col">
				{showfield ?
				<div className="col">
					<div className="form-check opacity-75">
						<label className="text-white slogen">{locallabels.greenhome}</label>
						<input type="checkbox" className="form-check-input" id="greenhome" name="greenhome" onChange={handlechange} value='y' checked={currentgreenhome === 'y'} />
					</div>
				</div>
				: ""}
				</div>
				<div className="col">
				{showfield ?
				<div className="col">
					<div className="form-check opacity-75">
						<label className="text-white slogen">{locallabels.newbuild}</label>
						<input type="checkbox" className="form-check-input" id="newbuild" name="newbuild" onChange={handlechange} value='y' checked={currentnewbuilt === 'y'} />
					</div>
				</div>
				: ""}
				</div>
			</div>
			</div>
			
		</div>
	}
	
	const removesubtype = (subtypeid) => {	
		setselectedsubtypes(selectedsubtypes.filter((i)=>(i.property_subtype_id !== subtypeid)));
	}
	
	const addsubtype = (e) => {
		if (e.target.value > 0 && selectedsubtypes.findIndex(subtype => subtype.property_subtype_id === e.target.value) === -1)
		{
			setselectedsubtypes(selectedsubtypes => [...selectedsubtypes, {"property_subtype_id": e.target.value, 
			"property_subtype_name": e.target.name}]);
		}
	}
	
	const handlesubtypechange = (e) => {
		
		if (e.target.name === "subtype0" && e.target.checked)
			setselectedsubtypes([]);
		else
		{
			if (e.target.checked)
				addsubtype(e);
			else
				removesubtype(e.target.value);
		}
	} 
	
	const removestate = (stateid) => {	
		setselectedstate(selectedstate.filter((i)=>(i.property_state_id !== stateid)));		
	}
	
	const addstate = (e) => {
		if (e.target.value > 0 && selectedstate.findIndex(state => state.property_state_id === e.target.value) === -1)
		{
			setselectedstate(selectedstate => [...selectedstate, {"property_state_id": e.target.value, 
			"property_state_name": e.target.name}]);
		}
	}
	
	const handlestatechange = (e) => {
		
		if (e.target.name === "state0" && e.target.checked)
			setselectedstate([]);
		else
		{
			if (e.target.checked)
				addstate(e);
			else
				removestate(e.target.value);
		}
	} 
	
	const removeorientation = (orientationid) => {	
		setselectedorientation(selectedorientation.filter((i)=>(i.orientation_id !== orientationid)));		
	}
	
	const addorientation = (e) => {
		if (e.target.value > 0 && selectedorientation.findIndex(orientation => orientation.orientation_id === e.target.value) === -1)
		{
			setselectedorientation(selectedorientation => [...selectedorientation, {"orientation_id": e.target.value, 
			"orientation_name": e.target.name}]);
		}
	}
	
	const handleorientationchange = (e) => {
		
		if (e.target.name === "orientation0" && e.target.checked)
			setselectedorientation([]);
		else
		{
			if (e.target.checked)
				addorientation(e);
			else
				removeorientation(e.target.value);
		}
	} 
		
	const removeheatingtypes = (heatingtypesid) => {	
		setselectedheatingtypes(selectedheatingtypes.filter((i)=>(i.heating_type_id !== heatingtypesid)));		
	}
	
	const addheatingtypes = (e) => {
		if (e.target.value > 0 && selectedheatingtypes.findIndex(heatingtypes => heatingtypes.heating_type_id === e.target.value) === -1)
		{
			setselectedheatingtypes(selectedheatingtypes => [...selectedheatingtypes, {"heating_type_id": e.target.value, 
			"heating_type_name": e.target.name}]);
		}
	}
	
	const handleheatingtypeschange = (e) => {
		
		if (e.target.name === "heatingtypes0" && e.target.checked)
			setselectedheatingtypes([]);
		else
		{
			if (e.target.checked)
				addheatingtypes(e);
			else
				removeheatingtypes(e.target.value);
		}
	} 
		
	const removeparking = (parkingid) => {	
		setselectedparking(selectedparking.filter((i)=>(i.parking_id !== parkingid)));		
	}
	
	const addparking = (e) => {
		if (e.target.value > 0 && selectedparking.findIndex(parking => parking.parking_id === e.target.value) === -1)
		{
			setselectedparking(selectedparking => [...selectedparking, {"parking_id": e.target.value, 
			"parking_name": e.target.name}]);
		}
	}
	
	const handleparkingchange = (e) => {
		
		if (e.target.name === "parking0" && e.target.checked)
			setselectedparking([]);
		else
		{
			if (e.target.checked)
				addparking(e);
			else
				removeparking(e.target.value);
		}
	} 
	
	const removecomfort = (comfortid) => {	
		setselectedcomfort(selectedcomfort.filter((i)=>(i.comfort_id !== comfortid)));		
	}
	
	const addcomfort = (e) => {
		if (e.target.value > 0 && selectedcomfort.findIndex(comfort => comfort.comfort_id === e.target.value) === -1)
		{
			setselectedcomfort(selectedcomfort => [...selectedcomfort, {"comfort_id": e.target.value, 
			"comfort_name": e.target.name}]);
		}
	}
	
	const handlecomfortchange = (e) => {
		
		if (e.target.name === "comfort0" && e.target.checked)
			setselectedcomfort([]);
		else
		{
			if (e.target.checked)
				addcomfort(e);
			else
				removecomfort(e.target.value);
		}
	} 
	
	const removebath = (bathid) => {	
		setselectedbath(selectedbath.filter((i)=>(i.bath_id !== bathid)));		
	}
	
	const addbath = (e) => {
		if (e.target.value > 0 && selectedbath.findIndex(bath => bath.bath_id === e.target.value) === -1)
		{
			setselectedbath(selectedbath => [...selectedbath, {"bath_id": e.target.value, 
			"bath_name": e.target.name}]);
		}
	}
	
	const handlebathchange = (e) => {
		
		if (e.target.name === "bath0" && e.target.checked)
			setselectedbath([]);
		else
		{
			if (e.target.checked)
				addbath(e);
			else
				removebath(e.target.value);
		}
	} 
	
	function filterdetailscol1() {
		return <div className="col-md-2 text-black">
		<div className="accordion" id="leftfilter">
			<div id="subtypes">
				<div className="accordion-item mb-3">
					<h2 className="accordion-header greycolor" id="headingsubtypes">
						<button className="accordion-button greycolor" type="button" data-bs-toggle="collapse" data-bs-target="#asubtypes" aria-expanded="true" aria-controls="asubtypes">
							{locallabels.subtypes}
						</button>
					</h2>
					<div id="asubtypes" className="accordion-collapse collapse" aria-labelledby="headingsubtypes" data-bs-parent="#leftfilter">
						<div className="accordion-body">
							<div className="form-check">
								<label className="">{locallabels.all}</label>
								<input type="checkbox" className="form-check-input" id="subtype0" name="subtype0" checked={selectedsubtypes.length === 0} 
								onChange={handlesubtypechange} />														
							</div>
							{
								propertysubtypes.map((propertysubtype, key) => 
									key > 0 ?
									<div className="form-check" key={key}>
										<label className="">{propertysubtype.property_subtype_name}</label>
										<input type="checkbox" className="form-check-input" 
										id={"subtype"+propertysubtype.property_subtype_id} 
										name={propertysubtype.property_subtype_name} 
										value={propertysubtype.property_subtype_id}
										checked={selectedsubtypes.findIndex(selectedsubtype => selectedsubtype.property_subtype_id === propertysubtype.property_subtype_id) !== -1}
										onChange={handlesubtypechange} />														
									</div>
									: ""
								)
							}
						</div>
					</div>
				</div>
			</div>
			<div id="state">
				<div className="accordion-item mb-3">
					<h2 className="accordion-header" id="headingstate">
						<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#astate" aria-expanded="true" aria-controls="astate">
							{locallabels.property_state_id}
						</button>
					</h2>
					<div id="astate" className="accordion-collapse collapse" aria-labelledby="headingstate" data-bs-parent="#leftfilter">
						<div className="accordion-body">
							<div className="form-check">
								<label className="">{locallabels.all}</label>
								<input type="checkbox" className="form-check-input" id="state0" name="state0" checked={selectedstate.length === 0} onChange={handlestatechange} />														
							</div>
							{
								propertystates.map((propertystate, key) => 
									key > 0 ?
									<div className="form-check" key={key}>
										<label className="">{propertystate.property_state_name}</label>
										<input type="checkbox" className="form-check-input" 
										id={"state"+propertystate.property_state_id} 
										name={"state"+propertystate.property_state_id} value={propertystate.property_state_id} checked={selectedstate.findIndex(selectedstate => selectedstate.property_state_id === propertystate.property_state_id) !== -1} 
										onChange={handlestatechange} />														
									</div>
									: ""
								)
							}
						</div>
					</div>
				</div>
			</div>
			<div id="orientation">
				<div className="accordion-item mb-3">
					<h2 className="accordion-header" id="headingorientation">
						<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#aorientation" aria-expanded="true" aria-controls="aorientation">
							{locallabels.orientation_id}
						</button>
					</h2>
					<div id="aorientation" className="accordion-collapse collapse" aria-labelledby="headingorientation" data-bs-parent="#leftfilter">
						<div className="accordion-body">
							<div className="form-check">
								<label className="">{locallabels.all}</label>
								<input type="checkbox" className="form-check-input" id="orientation0" name="orientation0" checked={selectedorientation.length === 0}  onChange={handleorientationchange} />														
							</div>
							{
								orientations.map((orientation, key) => 
									key > 0 ?
									<div className="form-check" key={key}>
										<label className="">{orientation.orientation_name}</label>
										<input type="checkbox" className="form-check-input" 
										id={"orientation"+orientation.orientation_id}
										name={"orientation"+orientation.orientation_id} value={orientation.orientation_id} checked={selectedorientation.findIndex(tselectedorientation => tselectedorientation.orientation_id === orientation.orientation_id) !== -1} 
										onChange={handleorientationchange} />														
									</div>
									: ""
								)
							}
						</div>
					</div>
				</div>
			</div>
			<div id="heating">
				<div className="accordion-item mb-3">
					<h2 className="accordion-header" id="headingheatingtypes">
						<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#aheatingtypes" aria-expanded="true" aria-controls="aheatingtypes">
							{locallabels.heatingtypes}
						</button>
					</h2>
					<div id="aheatingtypes" className="accordion-collapse collapse" aria-labelledby="headingheatingtypes" data-bs-parent="#leftfilter">
						<div className="accordion-body">
							<div className="form-check">
								<label className="">{locallabels.all}</label>
								<input type="checkbox" className="form-check-input" id="heatingtypes0" name="heatingtypes0" checked={selectedheatingtypes.length === 0} onChange={handleheatingtypeschange} />														
							</div>
							{
								heatingtypes.map((heatingtype, key) => 
									key > 0 ?
									<div className="form-check" key={key}>
										<label className="">{heatingtype.heating_type_name}</label>
										<input type="checkbox" className="form-check-input" 
										id={"heatingtypes"+heatingtype.heating_type_id} 
										name={"heatingtypes"+heatingtype.heating_type_id} value={heatingtype.heating_type_id} checked={selectedheatingtypes.findIndex(selectedheatingtype => selectedheatingtype.heating_type_id === heatingtype.heating_type_id) !== -1} 
										onChange={handleheatingtypeschange} />														
									</div>
									: ""
								)
							}
						</div>
					</div>
				</div>
			</div>
			<div id="parking">
				<div className="accordion-item mb-3">
					<h2 className="accordion-header" id="headingparking">
						<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#aparking" aria-expanded="true" aria-controls="aparking">
							{locallabels.parking}
						</button>
					</h2>
					<div id="aparking" className="accordion-collapse collapse " aria-labelledby="headingparking" data-bs-parent="#leftfilter">
						<div className="accordion-body">
							<div className="form-check">
								<label className="">{locallabels.all}</label>
								<input type="checkbox" className="form-check-input" id="parking0" name="parking0" checked={selectedparking.length === 0} onChange={handleparkingchange} />														
							</div>
							{
								parkings.map((parking, key) => 
									key > 0 ?
									<div className="form-check" key={key}>
										<label className="">{parking.parking_name}</label>
										<input type="checkbox" className="form-check-input" 
										id={"parking"+parking.parking_id} 
										name={"parking"+parking.parking_id}  value={parking.parking_id} checked={selectedparking.findIndex(tselectedparking => tselectedparking.parking_id === parking.parking_id) !== -1} 
										onChange={handleparkingchange} />														
									</div>
									: ""
								)
							}
						</div>
					</div>
				</div>
			</div>
			<div id="comfort">
				<div className="accordion-item mb-3">
					<h2 className="accordion-header" id="headingcomfort">
						<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#acomfort" aria-expanded="true" aria-controls="acomfort">
							{locallabels.comfort_id}
						</button>
					</h2>
					<div id="acomfort" className="accordion-collapse collapse" aria-labelledby="headingcomfort" data-bs-parent="#leftfilter">
						<div className="accordion-body">
							<div className="form-check">
								<label className="">{locallabels.all}</label>
								<input type="checkbox" className="form-check-input" id="comfort0" name="comfort0" checked={selectedcomfort.length === 0} onChange={handlecomfortchange}  />														
							</div>
							{
								comforts.map((comfort, key) => 
									key > 0 ?
									<div className="form-check" key={key}>
										<label className="">{comfort.comfort_name}</label>
										<input type="checkbox" className="form-check-input" 
										id={"comfort"+comfort.comfort_id} 
										name={"comfort"+comfort.comfort_id} value={comfort.comfort_id}  checked={selectedcomfort.findIndex(tselectedcomfort => tselectedcomfort.comfort_id === comfort.comfort_id) !== -1} 
										onChange={handlecomfortchange}  />														
									</div>
									: ""
								)
							}
						</div>
					</div>
				</div>
			</div>
			<div id="bath">
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingbath">
						<button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#abath" aria-expanded="true" aria-controls="abath">
							{locallabels.bath}
						</button>
					</h2>
					<div id="abath" className="accordion-collapse collapse" aria-labelledby="headingbath" data-bs-parent="#leftfilter">
						<div className="accordion-body">
							<div className="form-check">
								<label className="">{locallabels.all}</label>
								<input type="checkbox" className="form-check-input" id="bath0" name="bath0" checked={selectedbath.length === 0} onChange={handlebathchange} />														
							</div>
							{
								baths.map((bath, key) => 
									key > 0 ?
									<div className="form-check" key={key}>
										<label className="">{bath.bath_name}</label>
										<input type="checkbox" className="form-check-input" 
										id={"bath"+bath.bath_id}
										name={"bath"+bath.bath_id} value={bath.bath_id} checked={selectedbath.findIndex(tselectedbath => tselectedbath.bath_id === bath.bath_id) !== -1}
										onChange={handlebathchange} />														
									</div>
									: ""
								)
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	}
	
	function filterdetailscol2() {
		return <div className="col-md">
		<div className="row">
		<div id="level" className="mb-3">
			<label>{locallabels.property_level_id}</label>
			<div className="row row-cols-3">
				<div className="col-md">
					<select name="levelsfrom" id="levelsfrom" className="form-select" onChange={handlechange} value={currentlevelfrom}>
						<option value="0">
							{locallabels.all}
						</option>
					{
						levels.map((level, key) => 
							key > 0 ?
							<option key={key} value={level.property_level_id}>
									{level.property_level_name}
							</option>
							: ""
						)
					}
					</select>
				</div>
				<div className="col-md-1">
					<span> - </span>
				</div>
				<div className="col-md">
					<select name="levelsto" id="levelsto" className="form-select" onChange={handlechange} value={currentlevelto}>
						<option value="0">
							{locallabels.all}
						</option>
					{
						levels.map((level, key) => 
							key > 0 ?
							<option key={key} value={level.property_level_id}>
									{level.property_level_name}
							</option>
							: ""
						)
					}
					</select>
				</div>
			</div>
		</div>
		</div>
		<div className="row row-cols-4">
		<div id="ceilingheight" className="mb-3">
			<label>{locallabels.ceiling_height_id}</label>
			<select name="ceilingheight" id="ceilingheight" className="form-select" onChange={handlechange} value={currentceilingheight}>
				<option value="0">
					{locallabels.all}
				</option>
			{
				ceilingheights.map((ceilingheight, key) => 
					key > 0 ?
					<option key={key} value={ceilingheight.ceiling_height_id}>
							{ceilingheight.ceiling_height_name}
					</option>
					: ""
				)
			}
			</select>
		</div>
		<div id="lift" className="mb-3">
			{locallabels.elevator}
			<div className="form-check">
				<input className="form-check-input" type="checkbox" name="lift" id="liftyes" checked={currentelevator === 'y'} value="y" onChange={handlechange} />
				<label className="form-check-label">
					{locallabels.yes}
				</label>
			</div>
		</div>
		<div id="cellar" className="mb-3">
			{locallabels.cellar}
			<div className="form-check">
				<input className="form-check-input" type="checkbox" name="cellar" id="cellaryes" checked={currentcellar === 'y'} value="y" onChange={handlechange} />
				<label className="form-check-label">
					{locallabels.yes}
				</label>
			</div>
		</div>
		<div id="balcon" className="mb-3">
			{locallabels.balcon}			
			<div className="form-check">
				<input className="form-check-input" type="checkbox" name="balcon" id="balconyes" checked={currentbalcon === 'y'} value="y" onChange={handlechange} />
				<label className="form-check-label">
					{locallabels.yes}
				</label>
			</div>
		</div>						
			<div id="furnished" className="mb-3">
				<label>{locallabels.furnished_id}</label>
				<select name="furnished" id="furnished" className="form-select" onChange={handlechange} value={currentfurnished}>
					<option value="0">
						{locallabels.all}
					</option>
				{
					furnisheds.map((furnished, key) => 
						key > 0 ?
						<option key={key} value={furnished.furnished_id}>
								{furnished.furnished_name}
						</option>
						: ""
					)
				}
				</select>
			</div>
			<div id="machines" className="mb-3">
				{locallabels.mechanised}
				<div className="form-check">
					<input className="form-check-input" type="checkbox" name="machines" id="machinesyes" checked={currentmechanised === 'y'} value="y" onChange={handlechange} />
					<label className="form-check-label">
						{locallabels.yes}
					</label>
				</div>
			</div>
			<div id="pet" className="mb-3">
				<label>{locallabels.pets}</label>
				<select name="pet" id="pet" className="form-select" onChange={handlechange} value={currentpet}>
					<option value="0">
						{locallabels.all}
					</option>
				{
					pets.map((pet, key) => 
						key > 0 ?
						<option key={key} value={pet.pet_id}>
								{pet.pet_name}
						</option>
						: ""
					)
				}
				</select>
			</div>
			<div id="tenant" className="mb-3">
				<label>{locallabels.tenant}</label>
				<select name="tenant" id="Tenant" className="form-select" onChange={handlechange} value={currenttenant}>
					<option value="0">
						{locallabels.all}
					</option>
				{
					tenants.map((tenant, key) => 
						key > 0 ?
						<option key={key} value={tenant.tenant_id}>
								{tenant.tenant_name}
						</option>
						: ""
					)
				}
				</select>
			</div>
			<div id="smoking" className="mb-3">
				{locallabels.smoking}
				<div className="form-check">
					<input className="form-check-input" type="checkbox" name="smoking" id="smokingyes" checked={currentsmoking === 'y'} value="y" onChange={handlechange} />
					<label className="form-check-label">
						{locallabels.yes}
					</label>
				</div>
			</div>
		<div id="barrierfree" className="mb-3">
			{locallabels.isaccessible}
			<div className="form-check">
				<input className="form-check-input" type="checkbox" name="barrierfree" id="barrierfreeyes" checked={currentisaccessable === 'y'} value="y" onChange={handlechange} />
				<label className="form-check-label">
					{locallabels.yes}
				</label>
			</div>
		</div>
		<div id="withgarden" className="mb-3">
			{locallabels.garden_connection}
			<div className="form-check">
				<input className="form-check-input" type="checkbox" name="withgarden" id="withgardenyes" checked={currentgardenconnection === 'y'} value="y" onChange={handlechange} />
				<label className="form-check-label">
					{locallabels.yes}
				</label>
			</div>
		</div>
		<div id="aircondition" className="mb-3">
			{locallabels.aircondition}
			<div className="form-check">
				<input className="form-check-input" type="checkbox" name="aircondition" id="airconditionyes" checked={currentaircondition === 'y'} value="y" onChange={handlechange} />
				<label className="form-check-label">
					{locallabels.yes}
				</label>
			</div>
		</div>
	</div>
	</div>
	}
	
	function filterdetailscol3() {
		return <div className="col-md-3">
			<div className="mb-3">
				<label>{locallabels.searchtext}</label>
				<input className="form-control" type="text" placeholder={locallabels.searchtext} name="texttoinclude" onChange={handlechange} value={currenttext} />
			</div>			
		</div>
	}
	
	function filterdetails() {
		return 	<div className="collapse lightbg bg-gradient p-3" id="cpdetailedsearch">
			<div className="row">
				{filterdetailscol1()}
				{filterdetailscol2()}
				{filterdetailscol3()}
			</div>
		</div>
	}
	
	const removedistrict = (districtid) => {	
		setselecteddistricts(selecteddistricts.filter((i)=>(i.district_id !== districtid)))		
	}
	
	const adddistrict = (e) => {
		if (e.target.value > 0 && selecteddistricts.findIndex(district => district.district_id === e.target.value) === -1)
		{
			setselecteddistricts(selecteddistricts => [...selecteddistricts, {"district_id": e.target.value, 
			"district_name": e.target.name}]);			
		}
	}
	
	const handledistrictchange = (e) => {
		
		if (e.target.name === "district0" && e.target.checked)
			setselecteddistricts([]);
		else
		{
			if (e.target.checked)
				adddistrict(e);
			else
				removedistrict(e.target.value);
		}
	} 
	
	const removecounty = (countyid) => {	
		setselectedcounties(selectedcounties.filter((i)=>(i.county_id !== countyid)))
	}
	
	const addcounty = (e) => {
		if (e.target.value > 0 && selectedcounties.findIndex(county => county.county_id === e.target.value) === -1)
			setselectedcounties(selectedcounties => [...selectedcounties, {"county_id": e.target.value, 
			"county_name": e.target.name}]);
	}
	
	const handlecountychange = (e) => {
		
		if (e.target.name === "county0" && e.target.checked)
			setselectedcounties([]);
		else
		{
			if (e.target.checked)
				addcounty(e);
			else
				removecounty(e.target.value);
		}
	} 
	
	const removecity = (cityid) => {	
		setselectedcities(selectedcities.filter((i)=>(i.city_id !== cityid)))
	}
	
	const addcity = (city_id, city_name) => {
		if (city_id > 0 && selectedcities.findIndex(city => city.city_id === city_id) === -1)
			setselectedcities(selectedcities => [...selectedcities, {"city_id": city_id, 
			"city_name": city_name}]);
	}
	
	const filtercitychange = (e) => {		
		var inputparm = "";
		
		inputparm = "{";
		inputparm += '"tablename": "cities"';		
		inputparm += ', "filters": [{"' + e.target.name + '": "' + e.target.value + '"}]';
		inputparm += "}";
        axios.post(localapibase+'filtercity.php',inputparm).then(function(response) {
			setcities(response.data);
		})
	} 
	
	function locationmodal() {
		return <div className="modal fade" id="dselectlocation" tabIndex="-1" aria-labelledby="dselectlocationLabel" aria-hidden="true">	
			<div className="modal-dialog modal-lg">
				<div className="modal-content lightbg">
					<div className="modal-header border-bottom-0 p-3 pb-0 mb-0">
						<h5 className="modal-title" id="dMessageLabel">{locallabels.selectlocationtitle}</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body p-3 pt-0">
						{listselectedlocations()}
						<nav>
							<div className="nav nav-tabs" id="nav-tab" role="tablist">
								<button className="nav-link" id="nav-varosok-tab" data-bs-toggle="tab" data-bs-target="#nav-varosok" type="button" role="tab" aria-controls="nav-varosok" aria-selected="false">
									{locallabels.cities}
								</button>
								<button className="nav-link active" id="nav-bp-tab" data-bs-toggle="tab" data-bs-target="#nav-bp" type="button" role="tab" aria-controls="nav-bp" aria-selected="true">
									{locallabels.district}
								</button>
								{showfield ? 
								<button className="nav-link" id="nav-megyek-tab" data-bs-toggle="tab" data-bs-target="#nav-megyek" type="button" role="tab" aria-controls="nav-megyek" aria-selected="false">
									{locallabels.counties}
								</button>
								: ""}			
							</div>
						</nav>
						<div className="tab-content" id="nav-tabContent">
							<div className="tab-pane fade" id="nav-varosok" role="tabpanel" aria-labelledby="nav-varosok-tab">
								<label>{locallabels.city}</label>
								<div className="dropdown-content">
									<input name="city_name" className="form-select mt-4" onKeyUp={filtercitychange} autoComplete="off" placeholder="Kraków" />
									{
										cities.map((city, key) => 
											<span className="dropdown-item" key={key} onClick={() => addcity(city.city_id, city.city_name)}>
													{city.city_name}
											</span>
										)
									}
								</div>
							</div>
							<div className="tab-pane fade show active" id="nav-bp" role="tabpanel" aria-labelledby="nav-bp-tab">
								<div className="row row-cols-2 m-3">
								<div className="form-check">
									<label className="">{locallabels.all}</label>
									<input type="checkbox" className="form-check-input" id="district0" name="district0" checked={selecteddistricts.length === 0} 
										onChange={handledistrictchange} />														
								</div>							
								{
									districts.map((district, key) => 
										<div className="form-check" key={key}>
											<label className="">{district.district_name + ' ('+district.qty+')'}</label>
											<input type="checkbox" className="form-check-input" 
											id={"district"+district.district_id} 
											name={district.district_name} 
											value={district.district_id}
											checked={selecteddistricts.findIndex(selecteddistrict => selecteddistrict.district_id === district.district_id) !== -1}
											onChange={handledistrictchange} /> 
										</div>
									)
								}
								</div>
							</div>
							{ showfield ?
							<div className="tab-pane fade" id="nav-megyek" role="tabpanel" aria-labelledby="nav-megyek-tab">
								<div className="row row-cols-2 m-3">
								<div className="form-check">
									<label className="">{locallabels.all}</label>
									<input type="checkbox" className="form-check-input" id="county0" name="county0" checked={selectedcounties.length === 0}
									onChange={handlecountychange} />														
								</div>
								{
									counties.map((county, key) => 
										<div className="form-check" key={key}>
											<label className="">{county.county_name}</label>
											<input type="checkbox" className="form-check-input" 
											id={"county"+county.county_id} 
											name={county.county_name} 
											value={county.county_id}
											checked={selectedcounties.findIndex(selectedcounty => selectedcounty.county_id === county.county_id) !== -1}
											onChange={handlecountychange} />														
										</div>
									)
								}
								</div>
							</div>
							: ""}
						</div>	
						<div className="w-100 justify-content-end text-end">
							<button type="button" className="btn redbg text-white btnflat mt-5" data-bs-dismiss="modal" aria-label="Ok">OK</button>	
						</div>						
					</div>					
				</div>
			</div>			
		</div>
	}
	
	const filterclick = () => {
		let tmpfilters = {};
		tmpfilters["districts"] = selecteddistricts;
		tmpfilters["counties"] = selectedcounties;
		tmpfilters["cities"] = selectedcities;
		tmpfilters["subtypes"] = selectedsubtypes;
		tmpfilters["propertystates"] = selectedstate;
		tmpfilters["orientation"] = selectedorientation;
		tmpfilters["heatingtypes"] = selectedheatingtypes;
		tmpfilters["parking"] = selectedparking;
		tmpfilters["comfort"] = selectedcomfort;
		tmpfilters["bath"] = selectedbath;
		tmpfilters["category"] = currentcategory;
		tmpfilters["propertytype"] = currentpropertytype;
		tmpfilters["propertyid"] = currentpropertyid;
		tmpfilters["pricefrom"] = currentpricefrom;
		tmpfilters["priceto"] = currentpriceto;
		tmpfilters["netsizefrom"] = currentnetsizefrom;
		tmpfilters["netsizeto"] = currentnetsizeto;
		tmpfilters["netroomfrom"] = currentnetroomfrom;
		tmpfilters["netroomto"] = currentnetroomto;
		tmpfilters["greenhome"] = currentgreenhome;
		tmpfilters["newbuilt"] = currentnewbuilt;
		tmpfilters["levelfrom"] = currentlevelfrom;
		tmpfilters["levelto"] = currentlevelto;
		tmpfilters["ceilingheight"] = currentceilingheight;
		tmpfilters["furnished"] = currentfurnished;
		tmpfilters["smoking"] = currentsmoking;
		tmpfilters["elevator"] = currentelevator;
		tmpfilters["mechanised"] = currentmechanised;
		tmpfilters["isaccessable"] = currentisaccessable;
		tmpfilters["cellar"] = currentcellar;
		tmpfilters["pet"] = currentpet;
		tmpfilters["gardenconnection"] = currentgardenconnection;
		tmpfilters["balcon"] = currentbalcon;
		tmpfilters["tenant"] = currenttenant;
		tmpfilters["aircondition"] = currentaircondition;
		tmpfilters["text"] = currenttext;
		
		setlocalfilter(tmpfilters);
	};
	
	const locationclick = () => {
		let tmpfilters = {};
		tmpfilters["districts"] = selecteddistricts;
		tmpfilters["counties"] = selectedcounties;
		tmpfilters["cities"] = selectedcities;
		tmpfilters["subtypes"] = selectedsubtypes;
		tmpfilters["propertystates"] = selectedstate;
		tmpfilters["orientation"] = selectedorientation;
		tmpfilters["heatingtypes"] = selectedheatingtypes;
		tmpfilters["parking"] = selectedparking;
		tmpfilters["comfort"] = selectedcomfort;
		tmpfilters["bath"] = selectedbath;
		tmpfilters["category"] = currentcategory;
		tmpfilters["propertytype"] = currentpropertytype;
		tmpfilters["propertyid"] = currentpropertyid;
		tmpfilters["pricefrom"] = currentpricefrom;
		tmpfilters["priceto"] = currentpriceto;
		tmpfilters["netsizefrom"] = currentnetsizefrom;
		tmpfilters["netsizeto"] = currentnetsizeto;
		tmpfilters["netroomfrom"] = currentnetroomfrom;
		tmpfilters["netroomto"] = currentnetroomto;
		tmpfilters["greenhome"] = currentgreenhome;
		tmpfilters["newbuilt"] = currentnewbuilt;
		tmpfilters["levelfrom"] = currentlevelfrom;
		tmpfilters["levelto"] = currentlevelto;
		tmpfilters["ceilingheight"] = currentceilingheight;
		tmpfilters["furnished"] = currentfurnished;
		tmpfilters["smoking"] = currentsmoking;
		tmpfilters["elevator"] = currentelevator;
		tmpfilters["mechanised"] = currentmechanised;
		tmpfilters["isaccessable"] = currentisaccessable;
		tmpfilters["cellar"] = currentcellar;
		tmpfilters["pet"] = currentpet;
		tmpfilters["gardenconnection"] = currentgardenconnection;
		tmpfilters["balcon"] = currentbalcon;
		tmpfilters["tenant"] = currenttenant;
		tmpfilters["aircondition"] = currentaircondition;
		tmpfilters["text"] = currenttext;
		
		Promise.all([axios.post(localapibase+'getdistrict.php',tmpfilters)]).then(async([resdistricts]) => {
			const tmpdistricts = await resdistricts.data;
			setdistricts(tmpdistricts);	
		}).catch(error => {
			console.log(error);
		});	
	};
	
	return (
		<div id="dfilter" className="fcontainer container-fluid position-relative m-0 p-0 fullheight" style={filterStyle}>	
			{filtermainrow()}
			{filterdetails()}
			{locationmodal()}			
		</div>	
	);
}  