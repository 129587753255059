import axios from "axios"
import { useEffect, useState } from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import ListProperties from './listproperties.js';
import AllProperties from './allproperties.js';
import DisplayProperty from './displayproperty.js';
import DataProtecting from './dataprotecting.js';
import Services from './servicesfull.js';
import Contact from './contact.js';
import MainFooter from './footer.js';
import { createBrowserHistory } from 'history';
import './main.css';
import MainHeader from './header.js';

export const history = createBrowserHistory({
	basename: process.env.PUBLIC_URL
});
	
function App() {
		const [config, setconfig] = useState([]);
	const [labels, setlabels] = useState([]);
	const [canloadlabels, setcanloadlabels] = useState(false);
	
	useEffect(() => {
		let toset = true;
		const getconfig = async () => {
			const response = await axios.get('./assets/config.json');
			const tmpresult = await response.data;
			if (toset) {
				setconfig(tmpresult);		
				setcanloadlabels(true);				
			}			
		}
		getconfig().catch(console.error);
		
		const getlabels = async () => {
			document.title = config.pagetitle;
			const response = await axios.get('./assets/' + config.labelfile);
			const tmpresult = await response.data;
			if (toset) {
				setlabels(tmpresult);							
			}			
		}
		if (canloadlabels)
			getlabels().catch(console.error);
    }, [config.pagetitle, config.labelfile, canloadlabels]);

	if (config.length === 0) {
		return <h5>Loading.....</h5>;
	}

	if (labels.length === 0) {
		return <h5>Loading.....</h5>;
	}
	
	/*
	const [config, setconfig] = useState([]);
	const [labels, setlabels] = useState([]);
	
	const getconfig = useCallback(() => {
		Promise.all([
			axios.get('./assets/config.json'),		
			axios.get('./assets/labels-en.json')							
		]).then(async([resconfig, reslabel]) => {
			const tmpconfig = await resconfig.data;
			setconfig(tmpconfig);
			
			const tmplabel = await reslabel.data;
			setlabels(tmplabel);
		})
		.catch(error => {
			console.log(error);
		});
	}, []);
	
	useEffect(() => {
		getconfig();
    }, [getconfig]);

	if (config.length === 0) {
		return <h5>Loading.....</h5>;
	}
	
	if (labels.length === 0) {
		return <h5>Loading.....</h5>;
	}
	*/
	return (
		<div className="m-0 border-0 p-0">
			<MainHeader locallabels={labels} />	
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<Routes>
				  <Route index element={<ListProperties localapibase={config.apibase} locallabels={labels} imagebase={config.attachmentdir} />} />
				  <Route path={'/property/:id'} element={<DisplayProperty localapibase={config.apibase} locallabels={labels} imagebase={config.attachmentdir} />} />
				  <Route path={'/dataprotecting'} element={<DataProtecting localapibase={config.apibase} locallabels={labels} />} />
				  <Route path={'/allproperties'} element={<AllProperties localapibase={config.apibase} locallabels={labels} imagebase={config.attachmentdir} />} />
				  <Route path={'/services'} element={<Services localapibase={config.apibase} locallabels={labels} />} />
				  <Route path={'/contact'} element={<Contact localapibase={config.apibase} locallabels={labels} />} />
				</Routes>
			</BrowserRouter>
			<MainFooter localapibase={config.apibase} locallabels={labels} />
		</div>
	);
}

export default App;
