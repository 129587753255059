import axios from "axios"
import { useEffect, useState, useCallback } from "react";

export default function AllProperties({localapibase, locallabels, imagebase}) {
	const [ingatlanok, setingatlanok] = useState([]);
	const [pattachments, setpattachments] = useState([]);

	const getingatlanok = useCallback(() => {
        axios.get(localapibase+'ingatlanlistfull.php').then(function(response) {
            setingatlanok(response.data.properties);
			setpattachments(response.data.propertyattachments);
        }).catch(error => {
			console.log(error);
		});
    }, [localapibase]);
	
	useEffect(() => {
        getingatlanok();
    }, [getingatlanok]);
	
	if (ingatlanok === [])
		return <div></div>;
	
	function numberhandler(parmvalue) {
		let formatter = null;
		
		if(isNaN(parmvalue)){
			return ''
		} else {
			/*if (parmvalue >= 1000000)
			{
				formatter = new Intl.NumberFormat('pl-PL', {
					style: 'decimal',
					notation: "compact",
					maximumFractionDigits: 2,
					compactDisplay: "short"
				});
			}
			else {*/
				formatter = new Intl.NumberFormat('pl-PL', {
					maximumFractionDigits: 0,
					style: 'decimal'
				});  
			//}
			return formatter.format(parmvalue);
		}
	}  
	
	function numberhandlerdec(parmvalue) {
		let formatter = null;
		
		if(isNaN(parmvalue)){
			return ''
		} else {
			/*if (parmvalue >= 1000000)
			{
				formatter = new Intl.NumberFormat('pl-PL', {
					style: 'decimal',
					notation: "compact",
					maximumFractionDigits: 2,
					compactDisplay: "short"
				});
			}
			else {*/
				formatter = new Intl.NumberFormat('pl-PL', {
					maximumFractionDigits: 2,
					style: 'decimal'
				});  
			//}
			return formatter.format(parmvalue);
		}
	}  
	
	return(
		<div id="dtopprops" className="container-fluid ps-4 pt-3 pe-3">	
			<span className="fs-3">{locallabels.allproperties}</span>
	
			<div className="tcontainer row mt-3 mb-3 row-cols-md-3 row-cols-1 g-3 bgwhite">
				{
					(ingatlanok || []).map((ingatlan, key) =>
					<a href={'./property/'+ingatlan.property_id} className="main" key={key}>
						
	<div className="col">
					<div className="card cardborder mb-3">
						<div className='imgContainer'>
							
							<div id={"carouselGallery"+ingatlan.property_id} className="carousel justify-content-center" 
								data-bs-ride="carousel" data-bs-touch="true" data-bs-wrap="true" data-bs-interval="false">
								<div className="carousel-inner justify-content-center">								
									
									{										
										(pattachments[key][ingatlan.property_id] || []).map((pattachment, index) =>
										<div className={index===0 ? "carousel-item justify-content-center text-center active" : "carousel-item justify-content-center text-center" }
											key={index}>
											<img src={imagebase+pattachment.attachment_path+"/"+pattachment.attachment_name} className="w-100" alt={pattachment.attachment_name} />
										</div>
									)}
									
									<button className="carousel-control-prev left" type="button" data-bs-target={"#carouselGallery"+ingatlan.property_id} data-bs-slide="prev">
										<span className="carousel-control-prev-icon" aria-hidden="true"></span>
										<span className="visually-hidden">Previous</span>
									</button>
									<button className="carousel-control-next right" type="button" data-bs-target={"#carouselGallery"+ingatlan.property_id} data-bs-slide="next">
										<span className="carousel-control-next-icon" aria-hidden="true"></span>
										<span className="visually-hidden">Next</span>
									</button>		
															
								</div>
							</div>	
						
						</div>
						<div className="card-body p-2">
							<h5><b>
								{
								ingatlan.city_name
								}
								<span>, </span>
								{
								ingatlan.district_name !== null ? ingatlan.district_name : ingatlan.county_name
								}
							</b></h5>
							<p className="card-text m-0">
								<span className=""><b>{ingatlan.street !== "" ? ingatlan.street : "" }</b></span> 
							</p>
							<p className="card-text m-0 fs-6">
							<span className="">
							{ 
							((ingatlan.category_id || "").indexOf("1") !== -1) ?
								<span><span className="redtext">{numberhandler(ingatlan.salesprice)}</span><span className="ms-1">{ingatlan.currency_code}</span> </span>
							: ""}
							{ ((ingatlan.category_id || "").indexOf("1") !== -1 && (ingatlan.category_id || "").indexOf("2") !== -1) ?
							<span className="mx-3">-</span>
							: ""
							}
							{ 
							((ingatlan.category_id || "").indexOf("2") !== -1) ?
								<span><span className="redtext">{numberhandler(ingatlan.rental_price)}</span><span className="ms-1">{ingatlan.currency_code + " / " + locallabels.month}</span> </span>
							: ""}
							{ ((ingatlan.category_id || "").indexOf("1") !== -1 && (ingatlan.category_id || "").indexOf("2") !== -1) ?
							<br />
							: ""
							}
							{ ((ingatlan.category_id || "").indexOf("1") !== -1) ?
								<span><span className="ms-3 redtext">{ingatlan.grosssize > 0 ? numberhandler(ingatlan.salesprice / ingatlan.grosssize) : "-" }</span><span className="ms-1">{ingatlan.currency_code}/m<sup>2</sup></span> </span>
							: ""}
							</span>
							<br /> 
								<span className="redtext">{numberhandlerdec(ingatlan.grosssize)}</span><span className="ms-1">m<sup>2</sup></span> 
								<span className="ms-3">{locallabels.rooms + ":"}</span><span className="ms-1 redtext">{ingatlan.rooms}</span>
							</p>
						</div>						
					
						<div className="card-img-overlay text-end">
							<button className="btn redbg text-white btn-sm">{ingatlan.category + " " + ingatlan.property_type_name}</button>
						</div>
					</div>
					</div>
						
					</a>
				)}	
			</div>
		</div>
	);
}  