import React from 'react';

export default function MainHeader({locallabels}) {
	return(
		<div className="sticky-top">	
				<nav className="navbar navbar-dark greybg">
					<div className="row w-100 align-items-center">
					<div className="col-1 text-white ps-5">
					<button className="navbar-toggler btn-sm text-start" type="button" data-bs-toggle="collapse" data-bs-target="#navMainMenu" aria-controls="navMainMenu" 
						aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					</div>
					<div className="col justify-content-center text-center">
						<a href="./" className="navbar-brand text-center">
							<img src={"./Kepek/drmakkoslogopl.png"} height="90" alt="Dr Makkos PL" />
						</a>
					</div>
					</div>
				</nav>	
				<div className="collapse colnavMainMenu container-fluid p-0 m-0 bg-dark" id="navMainMenu">
					<div className="border-bottom ms-3 me-3"><a href="./" className="link-light ms-3 text-decoration-none greenlink">{locallabels.search}</a></div>
					<div className="border-bottom ms-3 me-3"><a href="./services" className="link-light ms-3 text-decoration-none greenlink">{locallabels.services}</a></div>
					<div className="ms-3 mb-3 me-3"><a href="./contact" className="link-light ms-3 text-decoration-none greenlink">{locallabels.contact}</a></div>
				</div>
		</div>	
	);
}  