import MainFilter from './filter.js';
import SearchProperties from './searchproperties.js';
import TopProperties from './topproperties.js';
import Services from './services.js';
import { useState } from "react";

export default function ListProperties({localapibase, locallabels, imagebase}) {
	const [localfilters, setlocalfilter] = useState([]);
	return(
		<div id="dmain" className="container-fluid m-0 border-0 p-0">		
			<MainFilter localapibase={localapibase} locallabels={locallabels} setlocalfilter={setlocalfilter} />
			{				
				localfilters.length === 0 ? 
				<div>
				<TopProperties localapibase={localapibase} locallabels={locallabels} imagebase= {imagebase} />
				<Services localapibase={localapibase} locallabels={locallabels} imagebase= {imagebase} />
				</div>
				:
				<SearchProperties localapibase={localapibase} locallabels={locallabels} imagebase= {imagebase} localfilters={localfilters} /> 	
			}
		</div>
	);
}  