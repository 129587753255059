import React from 'react';
import axios from "axios";
import { useEffect, useState, useCallback } from "react";

export default function Services({localapibase, locallabels}) {
	const [webtext1, setwebtext1] = useState([]);
	const [webtext2, setwebtext2] = useState([]);
	const [webtext3, setwebtext3] = useState([]);
	
	const gettext1 = useCallback(() => {
        axios.get(localapibase+'getwebsitetext.php/srvselling').then(function(response) {
            setwebtext1(response.data);
        }).catch(error => {
			 console.log(error.response.data.error);
		});
    }, [localapibase]);
	
	const gettext2 = useCallback(() => {
        axios.get(localapibase+'getwebsitetext.php/srvrenting').then(function(response) {
            setwebtext2(response.data);
        }).catch(error => {
			 console.log(error.response.data.error);
		});
    }, [localapibase]);
	
	const gettext3 = useCallback(() => {
        axios.get(localapibase+'getwebsitetext.php/srvbuying').then(function(response) {
            setwebtext3(response.data);
        }).catch(error => {
			 console.log(error.response.data.error);
		});
    }, [localapibase]);
	
	useEffect(() => {
        gettext1();
		gettext2();
		gettext3();
    }, [gettext1, gettext2, gettext3]);
	
	return(
		<div id="dservices" className="m-0 border-0 p-0">	
			<h5 className="m-0 border-0 ps-4 pt-3">{locallabels.services}</h5> 
			<div className="container-fluid justify-content-start m-0 border-0 p-3 row row-cols-md-3">
				
				<a href="./services#sale" className="main">
				<div className="card mb-3 h-100 tcard">
					<img src="./Kepek/services/propsell.jpg" className="card-img-top tcardimg" alt={locallabels.propertysale} />
					<div className="card-body p-0 pt-3">
						<h5><b>{(webtext1[0] || []).title}</b></h5>
						<p className="card-text">{(webtext1[0] || []).prologue}</p>					
					</div>
					<div className="card-img-overlay text-end">
						<button className="btn redbg text-white opacity-75">{locallabels.propertysale}</button>
					</div>					
				</div>
				</a>
				
				<a href="./services#rent" className="main">
				<div className="card mb-3 h-100 tcard">
					<img src="./Kepek/services/proprent.jpg" className="card-img-top tcardimg" alt={locallabels.propertyrent} />
					<div className="card-body p-0 pt-3">
						<h5><b>{(webtext2[0] || []).title}</b></h5>
						<p className="card-text">{(webtext2[0] || []).prologue}</p>
					</div>
					<div className="card-img-overlay text-end">
						<button className="btn redbg text-white opacity-75">{locallabels.propertyrent}</button>
					</div>
				</div>
				</a>
				
				<a href="./services#buy" className="main">
				<div className="card mb-3 h-100 tcard">
					<img src="./Kepek/services/propbuy.jpg" className="card-img-top tcardimg" alt={locallabels.propertybuy} />
					<div className="card-body p-0 pt-3">
						<h5><b>{(webtext3[0] || []).title}</b></h5>
						<p className="card-text">{(webtext3[0] || []).prologue}</p>
					</div>
					<div className="card-img-overlay text-end">
						<button className="btn redbg text-white opacity-75">{locallabels.propertybuy}</button>
					</div>
				</div>
				</a>
				
			</div>	
			
			<a href="./services" className="main">
			<p className="m-3 text-center">
				<button className="btnflat redbg text-white btn-lg">{locallabels.readmore}</button>
			</p>
			</a>
			
		</div>	
	);
}  