import axios from "axios"
import { useEffect, useState, useCallback } from "react";

export default function SearchProperties({localapibase, locallabels, localfilters, imagebase}) {
	const [ingatlanok, setingatlanok] = useState([]);
	const [ingatlandb, setingatlandb] = useState(0);
	const [pattachments, setpattachments] = useState([]);
	
	const getingatlanok = useCallback(() => {
        axios.post(localapibase+'filteringatlanlist.php',localfilters).then(function(response) {
            setingatlanok(response.data.properties);
			setingatlandb(response.data.property_count);
			setpattachments(response.data.propertyattachments);
        }).catch(error => {
			 console.log(error.response.data.error)
		});
    }, [localapibase, localfilters]);
	
	useEffect(() => {
        getingatlanok();
    }, [getingatlanok]);
	
	function numberhandler(parmvalue) {
		let formatter = null;
		
		if(isNaN(parmvalue)){
			return ''
		} else {
			/*if (parmvalue >= 1000000)
			{
				formatter = new Intl.NumberFormat('pl-PL', {
					style: 'decimal',
					notation: "compact",
					maximumFractionDigits: 2,
					compactDisplay: "short"
				});
			}
			else {*/
				formatter = new Intl.NumberFormat('pl-PL', {
					maximumFractionDigits: 0,
					style: 'decimal'
				});  
			//}
			return formatter.format(parmvalue);
		}
	}  
	
	function numberhandlerdec(parmvalue) {
		let formatter = null;
		
		if(isNaN(parmvalue)){
			return ''
		} else {
			/*if (parmvalue >= 1000000)
			{
				formatter = new Intl.NumberFormat('pl-PL', {
					style: 'decimal',
					notation: "compact",
					maximumFractionDigits: 2,
					compactDisplay: "short"
				});
			}
			else {*/
				formatter = new Intl.NumberFormat('pl-PL', {
					maximumFractionDigits: 2,
					style: 'decimal'
				});  
			//}
			return formatter.format(parmvalue);
		}
	}  
	
	return(
		<div id="dsearchprops" className="container-fluid ps-4 pt-3 pe-3">	
				<span className="fs-3">{locallabels.offers + ": (" + ingatlandb + ")"}</span>
				
				<div className="scontainer row mt-3 mb-3 row-cols-1 g-3">
					{
						(ingatlanok || []).map((ingatlan, key) =>
						<a href={'./property/'+ingatlan.property_id} className="main" key={key}>
						
				<div className="col p-0 m-0">
					<div className="card cardborder mb-5">
						<div className="row row-cols-md-2">
							<div className="col-md-4">
								<div className='imgContainer'>
									<div id={"carouselGallery"+ingatlan.property_id} className="carousel justify-content-center" 
								data-bs-ride="carousel" data-bs-touch="true" data-bs-wrap="true" data-bs-interval="false">
								<div className="carousel-inner justify-content-center">								
									
									{										
										(pattachments[key][ingatlan.property_id] || []).map((pattachment, index) =>
										<div className={index===0 ? "carousel-item justify-content-center text-center active" : "carousel-item justify-content-center text-center" }
											key={index}>
											<img src={imagebase+pattachment.attachment_path+"/"+pattachment.attachment_name} className="w-100" alt={pattachment.attachment_name} />
										</div>
									)}
									
									<button className="carousel-control-prev left" type="button" data-bs-target={"#carouselGallery"+ingatlan.property_id} data-bs-slide="prev">
										<span className="carousel-control-prev-icon" aria-hidden="true"></span>
										<span className="visually-hidden">Previous</span>
									</button>
									<button className="carousel-control-next right" type="button" data-bs-target={"#carouselGallery"+ingatlan.property_id} data-bs-slide="next">
										<span className="carousel-control-next-icon" aria-hidden="true"></span>
										<span className="visually-hidden">Next</span>
									</button>		
															
								</div>
							</div>	
							
								</div>
								<div className="card-img-overlay text-start">
									<button className="btn redbg text-white btn-sm">{ingatlan.category + " " + ingatlan.property_type_name}</button>
								</div>
							</div>							
							<div className="col-md-8 p-0 m-0">
								<div className="card-body p-2">
									<div className="row row-cols-md-2">
									<div className="col-md-8">
									
										<h5><b>
											{
											ingatlan.city_name
											}
											<span>, </span>
											{
											ingatlan.district_name !== null ? ingatlan.district_name : ingatlan.county_name
											}
										</b></h5>
										<p className="card-text m-0">
											<span className="">{ingatlan.street !== "" ? ingatlan.street : "" }</span> 
										</p>
										<p className="card-text m-0 fs-6">
											<label className="me-2">{locallabels.size + ":"}</label><span className="redtext">{numberhandlerdec(ingatlan.grosssize)}</span><span className="ms-1">m<sup>2</sup></span> 
											<label className="ms-3 me-2">{locallabels.rooms + ":"}</label><span className="redtext">{ingatlan.rooms}</span>
										</p>
										<p className="mt-3">
										{ingatlan.description}
										</p>
									</div>								
									<div className="col-md-4 text-end">
									{ ((ingatlan.category_id || "").indexOf("1") !== -1) ?
											<p className="card-text m-0 fs-2"><span className="redtext">{numberhandler(ingatlan.salesprice)}</span><span className="ms-1">{ingatlan.currency_code}</span> </p> 
										: ""}										
										{ ((ingatlan.category_id || "").indexOf("2") !== -1) ?
											<p className="card-text m-0 fs-2"><span className="redtext">{numberhandler(ingatlan.rental_price)}</span><span className="ms-1">{ingatlan.currency_code}</span></p>
										: ""}
										
										{ ((ingatlan.category_id || "").indexOf("1") !== -1) ?
										<p className="card-text m-0 fs-5">
											<span className="ms-3 redtext">{ingatlan.grosssize > 0 ? numberhandler(ingatlan.salesprice / ingatlan.grosssize) : "-" }</span><span className="ms-1">{ingatlan.currency_code}/m<sup>2</sup></span> 
										</p>
										: ""}
										<p className="card-text m-0 align-self-end">
											<button className="btnflat redbg text-white btn-sm">{locallabels.readmore}</button>
										</p>
									</div>
									</div>
								</div>		
							</div>
						</div>
						
					</div>
					</div>
						
					</a>
					)}	
				</div>
		</div>
	);
}  