import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import axios from "axios"
import { useEffect, useState } from "react";
import { useNavigate, useParams  } from "react-router-dom";

export default function DisplayProperty({localapibase, locallabels, imagebase}) {
	let navigate = useNavigate ();
	const [ingatlan, setingatlan] = useState([]);
	const [kepek, setkepek] = useState([]);
	const [kepeksmall, setkepeksmall] = useState([]);
	const [propcat, setpropcat] = useState([]);
	const [agent, setagent] = useState([]);
	const {id} = useParams();
	const [canload, setcanload] = useState(false);
	
	useEffect(() => {
        let toset = true;

		const getingatlandata = async () => {
			const response = await axios.get(localapibase+'ingatlaninfodisplay.php/'+id);
			const tmpresult = await response.data;
			if (toset) {
				setingatlan(tmpresult.property[0]);
				setagent(tmpresult.agent[0]);
				setkepek(tmpresult.propertyattachments);
				setpropcat(tmpresult.propertycategory);
				setkepeksmall(tmpresult.propertyattachmentssmall);	
				setcanload(true);				
			}
			
		}
		getingatlandata().catch(console.error);

		if (canload)
			loadCaptchaEnginge(6); 

		return () => toset = false;		
    }, [localapibase, id, canload]);
		
	const [formValue, setFormValue] = useState({
		sendername: '',
		senderemail: '',
		senderphone: '',
		messagetext: '',
		gdpragree: false,
		captharesponse: '',
	});
	const [formerrors, setformerrors] = useState([]);

	const formonChange = (e: any) => {
		let value;
		if (e.target.name === "gdpragree")
			value = e.target.checked;
		else
			value = e.target.value;
		setFormValue({ ...formValue, [e.target.name]: value });
	};	
	
	const hasformerror = (keyname) => {
		return formerrors.indexOf(keyname) !== -1;
	}
	
	const messageSend = (e) => {
		e.preventDefault();

		var tmperrors = [];
		
		if (formValue.sendername === "")
			tmperrors.push("sendername");
		
		if (formValue.senderemail === "")
			tmperrors.push("senderemail");
		
		if (formValue.senderphone === "")
			tmperrors.push("senderphone");
		
		if (formValue.messagetext === "")
			tmperrors.push("messagetext");
		
		if (formValue.gdpragree === false)
			tmperrors.push("gdpragree");
		
		if (formValue.captharesponse === "")
			tmperrors.push("captharesponse");
		
		setformerrors(tmperrors);
		
		if (tmperrors.length === 0)
		{
			let user_captcha = document.getElementById('captharesponse').value;

			if (validateCaptcha(user_captcha)===true) 
			{
				//sendform
				let formData = new FormData();
								
				let messageinput = formValue;
				messageinput["property_id"] = id;
								
				formData.append("messageinput", JSON.stringify(messageinput));

				axios.post(localapibase+"sendemail.php", formData,
				{
				  headers: {
					"Content-Type": "multipart/form-data"
				}}).then(function(response){
					const result = response.data;
					if (result[0].StatusCode === 200)
					{
						//OK
						document.getElementById('msgclosebutton').click(); 
						showmsgresult(true);
						setFormValue({
		sendername: '',
		senderemail: '',
		senderphone: '',
		messagetext: '',
		gdpragree: false,
		captharesponse: '',
	});
						document.getElementById('sendername').value = "";
						document.getElementById('senderphone').value = "";
						document.getElementById('senderemail').value = "";
						document.getElementById('messagetext').value = "";
						document.getElementById('gdpragree').checked = false;
					}
					else
					{
						//Error
						showmsgresult(false);
					}
				});	
								
				loadCaptchaEnginge(6); 
				document.getElementById('captharesponse').value = "";				
			}

			else {
				e.preventDefault();
				tmperrors.push("captharesponse");
				document.getElementById('captharesponse').value = "";
			}
		}
	};
   
	const showmsgresult = (tmpparm) => {
		alert(tmpparm ? locallabels.messagesent : locallabels.messagesenterror);

		return <div className="modal fade in" role="dialog" id="dMessageResult" tabIndex="-1" aria-labelledby="dMessageResultLabel" aria-hidden="false">
			<div className="modal-dialog">
				<div className="modal-content bluebgnoopacity">
					<div className="modal-header border-bottom-0 p-3 pb-0 mb-0">
						<h5 className="modal-title" id="dMessageLabel">{locallabels.messagesendingtitle}</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body p-3 pt-0">
					{
						tmpparm ? locallabels.messagesent : locallabels.messagesenterror
					}
					<button type="button" className="btn" data-bs-dismiss="modal" aria-label="btnok">Ok</button>
					</div>
				</div>
			</div>
		</div>
	}
	
	var headerStyle = {
		backgroundImage: "url(" + imagebase + ingatlan.attachment_path + '/' + ingatlan.property_mainpicture + ")",
		backgroundAttachment: "fixed",
		backgroundPosition: "top",
		backgroundRepeat: "no-repeat",	
		backgroundSize: "cover"
	};
	
	function handleAnchor(e, parm) {
		if (parm === "binformation")
			document.querySelector('[id="pinformation"]').scrollIntoView();
		if (parm === "bsize")
			document.querySelector('[id="psize"]').scrollIntoView();
		if (parm === "blocation")
			document.querySelector('[id="plocation"]').scrollIntoView();
		if (parm === "bphotos")
			document.querySelector('[id="pphotos"]').scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		if (parm === "bdescription")
			document.querySelector('[id="pdescription"]').scrollIntoView(true);
	}
	
	function numberhandler(parmvalue) {
		let formatter = null;
		
		if(isNaN(parmvalue)){
			return ''
		} else {
			/*if (parmvalue >= 1000000)
			{
				formatter = new Intl.NumberFormat('pl-PL', {
					style: 'decimal',
					notation: "compact",
					maximumFractionDigits: 2,
					compactDisplay: "short"
				});
			}
			else {*/
				formatter = new Intl.NumberFormat('pl-PL', {
					maximumFractionDigits: 0,
					style: 'decimal'
				});  
			//}
			return formatter.format(parmvalue);
		}
	}  

	function numberhandlerdec(parmvalue) {
		let formatter = null;
		
		if(isNaN(parmvalue)){
			return ''
		} else {
			/*if (parmvalue >= 1000000)
			{
				formatter = new Intl.NumberFormat('pl-PL', {
					style: 'decimal',
					notation: "compact",
					maximumFractionDigits: 2,
					compactDisplay: "short"
				});
			}
			else {*/
				formatter = new Intl.NumberFormat('pl-PL', {
					maximumFractionDigits: 2,
					style: 'decimal'
				});  
			//}
			return formatter.format(parmvalue);
		}
	}  
	
	return(
		<div id="dmain" className="m-0 border-0 p-0">		
			{
				canload ?
				<div>
				<div className="d-none d-md-flex container-fluid fullheight row p-0 m-0 align-items-end" style={headerStyle}>
					<div className="col">
					<div className="row row-cols-1">
					<div className="col-md-4 redbg text-white py-3">
						<div className="d-flex flex-row ps-2"><h5>{ingatlan.property_title}</h5> </div>
					</div>
					</div>
					
					<div className="row row-cols-1">
					<div className="col-md-6 greybg text-white py-3">
						<div className="d-flex flex-row">
						
						<div className="me-3 w-50">
							<h5><b>
								{
									ingatlan.category + " " + ingatlan.property_type_name 								
								}
								<span> - </span>
								{
								ingatlan.city_name
								}
								<span>, </span>
								<br />
								{
								ingatlan.district_name !== null ? ingatlan.district_name : ingatlan.county_name
								}
							</b></h5>
							<p>{ingatlan.street}</p>
						</div>
						
						<div>
							<div className="vr h-100 bg-white text-white"></div>
						</div>
						
						<div className="ms-3 text-end justify-content-end">
							<div  className="d-flex justify-content-end">
							{ (propcat.findIndex(cat => cat.category_id === "1") !== -1) ?
							<h5>{numberhandler(ingatlan.salesprice) + " " + ingatlan.currency_code}</h5> 
							: ""
							}
							{ (propcat.findIndex(cat => cat.category_id === "1") !== -1) && (propcat.findIndex(cat => cat.category_id === "2") !== -1) ?
							<h5 className="mx-3">-</h5>
							: ""
							}
							{ (propcat.findIndex(cat => cat.category_id === "2") !== -1) ?
							<h5>{numberhandler(ingatlan.rental_price) + " " + ingatlan.currency_code + " / " + locallabels.month}</h5> 
							: "" 
							}	
							</div>						
							{ (propcat.findIndex(cat => cat.category_id === "1") !== -1) ?
							<p>{(ingatlan.property_gross_size > 0 ? numberhandler(ingatlan.salesprice / ingatlan.property_gross_size) : "-") + " " + ingatlan.currency_code}/m<sup>2</sup></p>
							: ""}
						</div>
						
						</div>
						
					</div>
					</div>
					</div>
					
				</div>
				
				<div className='d-sm-flex d-md-none imgContainer'>
							
					<div id={"carouselGallery"+ingatlan.property_id} className="carousel justify-content-center" 
						data-bs-ride="carousel" data-bs-touch="true" data-bs-wrap="true" data-bs-interval="false">
						<div className="carousel-inner justify-content-center">								
							
							{										
								kepeksmall.map((kep, key) =>
								<div className={key===0 ? "carousel-item justify-content-center text-center active" : "carousel-item justify-content-center text-center" }
									key={key}>
									<img src={imagebase+kep.attachment_path+"/"+kep.attachment_name} className="w-100" alt={kep.attachment_name} />
								</div>
							)}
							
							<button className="carousel-control-prev left" type="button" data-bs-target={"#carouselGallery"+ingatlan.property_id} data-bs-slide="prev">
								<span className="carousel-control-prev-icon" aria-hidden="true"></span>
								<span className="visually-hidden">Previous</span>
							</button>
							<button className="carousel-control-next right" type="button" data-bs-target={"#carouselGallery"+ingatlan.property_id} data-bs-slide="next">
								<span className="carousel-control-next-icon" aria-hidden="true"></span>
								<span className="visually-hidden">Next</span>
							</button>		
													
						</div>
					</div>	
				
				</div>
				
				<div className="container-fluid row row-cols-1 row-cols-md-3 lightbg m-0 p-3">
					<div className="col text-start">
						<button  type="button" className="btn redbg text-white ms-2" onClick={() => navigate("/")}>
							{locallabels.backtolist}
						</button>
					</div>
					<div className="col">
						<div className="btn-group" role="group">							
							<button name="bphotos" type="button" className="btn btn-outline-red ms-2 my-2" onClick={e => handleAnchor(e,"bphotos")}>					
								{locallabels.photos}
							</button>
							<button name="bdescription" type="button" className="btn btn-outline-red ms-2 my-2" onClick={e => handleAnchor(e,"bdescription")}>					
								{locallabels.propertydescription}
							</button>
						</div>
					</div>
				</div>
				
				<div className="container-fluid lightbg m-0 p-3">
					<div className="row row-cols-1 row-cols-md-2 ms-3">
						<div className="col-md-10 bgwhite">
							
							<div id="plocation" className="row row-cols-1 row-cols-md-2 border-bottom border-secondary m-3">
								<div className="col-md-3 align-self-top">
									<b>{locallabels.propertylocation}</b>
								</div>
								<div className="col-md-9">
									<p><label className="me-2">{locallabels.address + ":"}</label><b>{ingatlan.place}</b></p>
								</div>
							</div>
							
							<div className="row row-cols-1 row-cols-md-2 border-bottom border-secondary m-3">
								<div className="col-md-3 align-self-top">
									<b>{locallabels.price}</b>
								</div>
								<div className="col-md-9">
									<div className="d-md-flex">
									{ (propcat.findIndex(cat => cat.category_id === "1") !== -1) ?
									<p className="me-3"><label className="me-2">{locallabels.price + ":"}</label><b>{numberhandler(ingatlan.salesprice) + " " + ingatlan.currency_code}</b></p> 
									: ""}
									{ (propcat.findIndex(cat => cat.category_id === "2") !== -1) ?
									<p className="me-3"><label className="me-2">{locallabels.rental_price + ":"}</label><b>{numberhandler(ingatlan.rental_price) + " " + ingatlan.currency_code + " / " + locallabels.month}</b></p> 
									: ""}
									{ (propcat.findIndex(cat => cat.category_id === "1") !== -1) ?
									<p className="me-3"><label className="me-2">{locallabels.pricepersqm + ":"}</label><b>{(ingatlan.property_gross_size > 0 ? numberhandler(ingatlan.salesprice / ingatlan.property_gross_size) : "-") + " " + ingatlan.currency_code}/m<sup>2</sup></b></p> 
									: ""}
									</div>
								</div>
							</div>
							
							<div id="pinformation" className="row row-cols-1 row-cols-md-2 border-bottom border-secondary m-3">
								<div className="col-md-3 align-self-top">
									<b>{locallabels.information}</b>
								</div>
								<div className="col-md-9">
									<div className="row row-cols-1 row-cols-md-2">
									<p><label>{locallabels.furnished_id}: </label> <b>{ingatlan.furnished_name}</b></p>
									{ (propcat.findIndex(cat => cat.category_id === "2") !== -1) ?
									<p><label>{locallabels.mechanised}: </label> <b>{ingatlan.mechanised === "y" || ingatlan.mechanised === true ? locallabels.yes : locallabels.no}</b></p>
									: "" }
									{ (propcat.findIndex(cat => cat.category_id === "2") !== -1) ?
									<p><label>{locallabels.smoking}: </label> <b>{ingatlan.smoking === "y" || ingatlan.smoking === true ? locallabels.yes : locallabels.no}</b></p>
									: "" }
									{ (propcat.findIndex(cat => cat.category_id === "2") !== -1) ?
									<p><label>{locallabels.overheads}: </label> <b>{numberhandler(ingatlan.overheads) + " " + ingatlan.currency_code}</b></p>
									: "" }
									{ (propcat.findIndex(cat => cat.category_id === "2") !== -1) ?
									<p><label>{locallabels.shared_costs}: </label> <b>{numberhandler(ingatlan.shared_costs) + " " + ingatlan.currency_code}</b></p>
									: "" }
									{ (propcat.findIndex(cat => cat.category_id === "2") !== -1) ?
									<p><label>{locallabels.min_rental_period}: </label> <b>{ingatlan.min_rental_period}</b></p>
									: "" }
									{ (propcat.findIndex(cat => cat.category_id === "2") !== -1) ?
									<p><label>{locallabels.deposit}: </label> <b>{ingatlan.deposit}</b></p>
									: "" }
									<p><label>{locallabels.balcony_size}: </label> <b>{numberhandlerdec(ingatlan.balcony_size)}</b></p>
									<p><label>{locallabels.property_state_id}: </label> <b>{ingatlan.property_state_name}</b></p>
									<p><label>{locallabels.available_from_id}: </label> <b>{ingatlan.available_from_name}</b></p>
									<p><label>{locallabels.orientation_id}: </label> <b>{ingatlan.orientation_name}</b></p>
									<p><label>{locallabels.view}: </label> <b>{ingatlan.property_view_name}</b></p>
									<p><label>{locallabels.garden_size}: </label> <b>{numberhandlerdec(ingatlan.garden_size)}</b></p>							
									<p><label>{locallabels.property_level_id}: </label> <b>{ingatlan.property_level_name}</b></p>
									<p><label>{locallabels.elevator}: </label> <b>{ingatlan.elevator === "y" || ingatlan.elevator === true ? locallabels.yes : locallabels.no}</b></p>
									<p><label>{locallabels.isaccessible}: </label> <b>{ingatlan.isaccessible === "y" || ingatlan.isaccessible === true ? locallabels.yes : locallabels.no}</b></p>
									<p><label>{locallabels.comfort_id}: </label> <b>{ingatlan.comfort_name}</b></p>
									<p><label>{locallabels.garden_connection}: </label> <b>{ingatlan.garden_connection === "y" || ingatlan.garden_connection === true ? locallabels.yes : locallabels.no}</b></p>
									<p><label>{locallabels.ceiling_height_id}: </label> <b>{ingatlan.ceiling_height_name}</b></p>						
									</div>									
									
								</div>
							</div>
							
							<div id="psize" className="row row-cols-1 row-cols-md-2 border-bottom border-secondary m-3">
								<div className="col-md-3 align-self-top">
									<b>{locallabels.size}</b>
								</div>
								<div className="col-md-9">
									<div className="row row-cols-1 row-cols-md-3">
									<p><label className="me-2">{locallabels.size + ":"}</label><b>{numberhandlerdec(ingatlan.property_gross_size) + " "}m<sup>2</sup></b></p>
									<p><label className="me-2">{locallabels.site_size + ":"}</label><b>{numberhandlerdec(ingatlan.site_size) + " "}m<sup>2</sup></b></p>
									<p><label className="me-2">{locallabels.rooms + ":"}</label><b>{numberhandler(ingatlan.rooms)}</b></p>
									</div>
								</div>
							</div>
							
							<div id="pphotos" className="d-none d-md-flex row row-cols-1 row-cols-md-2 border-bottom border-secondary m-3">
								<div className="col-md-3 align-self-top">
									<b>{locallabels.photos}</b>
								</div>
								<div className="col-md-9">
									<div className="row row-cols-1 row-cols-md-2 g-3 mb-3">
										
										{kepeksmall.map((kep, key) =>
										
											<div data-bs-toggle="modal" data-bs-target="#galeria" key={key} >
											<button className="border-0" data-bs-target="#carouselGallery" data-bs-slide-to={key}>
												<img src={imagebase+kep.attachment_path+"/"+kep.attachment_name} 
												className="w-100 border-0" alt={ingatlan.property_mainpicture}/>											
											</button>
											</div>
											
										)}
										
									</div>
								</div>
							</div>
							
							<div id="pdescription" className="row row-cols-1 row-cols-md-2 border-bottom border-secondary m-3">
								<div className="col-md-3 align-self-top">
									<b>{locallabels.propertydescription}</b>
								</div>
								<div className="col-md-9">
									<p className="">{ingatlan.description.split("\n").map(function(item, idx) {
										return (
											<span key={idx}>
												{item}
												<br/>
											</span>
										 )
									})}</p>
								</div>
							</div>

						</div>
						<div className="col-md-2">
						<div className="w-100 text-end fs-4 m-0 p-0 mb-3"><b>{ingatlan.property_id_txt}</b></div>
						{ agent ?
							<div className="card">
								<div className="card-header">
									<h5 className="card-title">{locallabels.contact}</h5>								
								</div>
								<img src={imagebase + "pictures/agents/" + agent.agent_picture} className="card-img-top w-100 p-1" alt="..." />
								<div className="card-body">
									<p className="card-text">{locallabels.agent + ": " + agent.agent_name}</p>
									<p className="card-text">{locallabels.phone + ": " + agent.agent_phone}</p>
									<button className="btn btn-sm redbg text-white" data-bs-toggle="modal" data-bs-target="#dMessage" >{locallabels.sendmessage}</button>
								</div>
							</div>
						: ""
						}
						</div>
					</div>
				</div>
				
				
				</div>	
				: ""
			}	
			
			
			<div className="modal fade" id="dMessage" tabIndex="-1" aria-labelledby="dMessageLabel" aria-hidden="true">
				<div className="modal-dialog">
					<div className="modal-content whitebg">
						<div className="modal-header border-bottom-0 p-3 pb-0 mb-0">
							<h5 className="modal-title" id="dMessageLabel">{locallabels.messagetitle}</h5>
							<button id="msgclosebutton" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body p-3 pt-0">
							<form id="messageForm" className="needs-validation" noValidate onSubmit={messageSend}>
								<div className="mb-3">
									<label className="col-form-label">{locallabels.property_id}: </label>
									<input type="text" className="form-control" id="property_id" defaultValue={ingatlan.property_id_txt} name="property_id" readOnly disabled />
								</div>
								<div className="mb-3">
									<label className="col-form-label">{locallabels.sendername}: </label>
									<sup><i className="fa fa-asterisk text-danger fs-7" aria-hidden="true"></i></sup>
									<input type="text" className={
									  hasformerror("sendername")
										? "form-control is-invalid"
										: "form-control"
									} id="sendername" name="sendername" required onChange={formonChange} />
									<div className="invalid-feedback">
										{locallabels.sendernameerror}
									</div>
								</div>
								<div className="mb-3">
									<label className="col-form-label">{locallabels.senderemail}: </label>
									<sup><i className="fa fa-asterisk text-danger fs-7" aria-hidden="true"></i></sup>
									<input type="email" className={
									  hasformerror("senderemail")
										? "form-control is-invalid"
										: "form-control"
									} id="senderemail" name="senderemail" required onChange={formonChange}  />
									<div className="invalid-feedback">
										{locallabels.senderemailerror}
									</div>
								</div>
								<div className="mb-3">
									<label className="col-form-label">{locallabels.senderphone}: </label>
									<sup><i className="fa fa-asterisk text-danger fs-7" aria-hidden="true"></i></sup>
									<input type="text" className={
									  hasformerror("senderphone")
										? "form-control is-invalid"
										: "form-control"
									} id="senderphone" name="senderphone" required onChange={formonChange}  />
									<div className="invalid-feedback">
										{locallabels.senderphoneerror}
									</div>
								</div>
								<div className="mb-3">
									<label className="col-form-label">{locallabels.message}: </label>
									<sup><i className="fa fa-asterisk text-danger fs-7" aria-hidden="true"></i></sup>
									<textarea className={
									  hasformerror("messagetext")
										? "form-control is-invalid"
										: "form-control"
									} id="messagetext" name="messagetext" required onChange={formonChange} ></textarea>
									<div className="invalid-feedback">
										{locallabels.messageerror}
									</div>
								</div>
								<div className="mb-3 form-group">
									<input type="checkbox" className={
									  hasformerror("gdpragree")
										? "form-check-input me-2 align-self-end is-invalid"
										: "form-check-input me-2 align-self-end"
									} id="gdpragree" name="gdpragree" required onChange={formonChange}  />
									<label className="">{locallabels.gdpragree}</label>
									<sup><i className="fa fa-asterisk text-danger fs-7" aria-hidden="true"></i></sup>
									<div className="invalid-feedback">
										{locallabels.gdpragreeerror}
									</div>
								</div>
								<div className="mt-5 mb-3 form-group">
									<div> 
										<LoadCanvasTemplate  />
									</div>
									<input type="text" className={
									  hasformerror("captharesponse")
										? "form-control is-invalid"
										: "form-control"
									} id="captharesponse" name="captharesponse" required autoComplete="off" onChange={formonChange}  />
									<div className="invalid-feedback">
										{locallabels.captchafillerror}
									</div>			
									<sup><i className="fa fa-asterisk text-danger fs-7" aria-hidden="true"></i></sup>										
								</div>
								<button type="submit" className="btn redbg text-white">{locallabels.sendmessage}</button>
							</form>
						</div>
					</div>
				</div>
			</div>
			
			<div
			  className="modal fade"
			  id="galeria"
			  aria-labelledby="galeria"
			  aria-hidden="true"
			>
				<div className="modal-dialog modal-fullscreen">
					<div className="modal-content">
						<div className="modal-header p-1">
							<div className="col-md fs-7">{canload ? ingatlan.place + " - " + ingatlan.price : ""}</div>
							<button type="button" className="btn-sm btn-close p-2" data-bs-dismiss="modal">
							</button>
						</div>
						<div className="modal-body pcontainer">
							<div id="carouselGallery" className="carousel justify-content-center" 
							data-bs-ride="carousel" data-bs-touch="true" data-bs-wrap="true" data-bs-interval="false">
								<div className="carousel-inner justify-content-center">
								{kepek.map((kep, key) =>
									<div className={key===0 ? "carousel-item active justify-content-center text-center" : "carousel-item justify-content-center text-center"} key={key}>
									{
										
										<img src={imagebase+kep.attachment_path+"/"+kep.attachment_name} 
											className="rounded" alt={kep.property_mainpicture} />									
									}
									</div>
								)}
								</div>	
								<button className="carousel-control-prev left carouselnav" type="button" data-bs-target="#carouselGallery" data-bs-slide="prev">
									<span className="carousel-control-prev-icon" aria-hidden="true"></span>
									<span className="visually-hidden">Previous</span>
								</button>
								<button className="carousel-control-next right carouselnav" type="button" data-bs-target="#carouselGallery" data-bs-slide="next">
									<span className="carousel-control-next-icon" aria-hidden="true"></span>
									<span className="visually-hidden">Next</span>
								</button>
								<div className="carousel-indicators">
								{kepeksmall.map((kep, key) =>
									<button type="button" data-bs-target="#carouselGallery" data-bs-slide-to={key} 
										className={key===0 ? "active d-block w-100" : "d-block w-100"}
										aria-current={key===0 ? "true" : "false"} aria-label={'slide'+key} key={key}>
										{
											<img src={imagebase+kep.attachment_path+"/"+kep.attachment_name} 
												className="d-block w-100 rounded img-thumbnail" alt={ingatlan.property_mainpicture} />											
										}
									</button>
								)}
								</div>	
							</div>
						</div>
					</div>
				</div>			
			</div>
		</div>
	);
}  